import { api } from "../../services/api"; 

const cepLoad = async (cep) => {
    const res = await api.get(`https://viacep.com.br/ws/${cep}/json/`);
    if (res.status === 200) {
        return res
    }
}

export default cepLoad
