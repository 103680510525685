import { api } from "../../services/api"
import toast from "react-hot-toast"

const UpdateUserData = async () => {
    const res = await api.get('/myuser')
    if (res.status === 200){
        toast.success('Usuário atualizado.')
        sessionStorage.setItem("user", JSON.stringify(res.data));
        if ('setScreenKey' in window)
            window.setScreenKey(Math.random())
        return
    }
    toast.error('Falha ao atualizar o usuário')
}

export default UpdateUserData