import React, { useReducer, useContext } from 'react'
import { lockedReducer } from '../../Utils/reducers'
import { api } from '../../../services/api'
import ColorButton from '../../Buttons/ColorButton';
import DefaultModal from '../../Utils/DefaultModal';
import toast from 'react-hot-toast'
import { Box, Tooltip, TextField, Select, InputLabel, FormControl, Chip, MenuItem } from '@mui/material'
import validateData from './validateData';
import { externalComponent } from '../../AppRoutes';
import { capitalize } from '../../Utils/functions'
import CancelIcon from '@mui/icons-material/Cancel'

const url = '/groupextension/'

export const translate_permissions_keys = {
    'Can': '',
    'add': 'Adicionar',
    'change': 'Alterar',
    'delete': 'Excluir',
    'view': 'Ver'
}

const translate = (string) => {
    let newstring = string
    Object.entries(translate_permissions_keys).forEach(([key, value])=> {newstring = newstring.replace(key, value)})
    return newstring
}

const getTitle = (data) => {
    if (data?.id)
        return 'Editar'
    return 'Adicionar'
}

const submit = async (data, getData, setLoader, handleClose) => {

    setLoader(true)
    let res = null
        
    if (data.id)
        res = await api.patch(`${url}${data.id}/`, data)
    else
        res = await api.post(url, data)
    if (res.status === 201 || res.status === 200) {
        toast.success(`Grupo ${res.status === 201 ? 'adicionado' : 'editado'} com sucesso`)
        getData()
        handleClose()
        setLoader(false)
        return
    }
    toast.error(`Grupo não pôde ser ${!data.id ? 'adicionado' : 'editado.'} Motivo: `)
    setLoader(false)
}

const EditGroupModal = ({ data, handleClose, permissions, reloadData }) => {

    const { setLoader } = useContext(externalComponent)

    const [state, setState] = useReducer(lockedReducer, {
        id: data.id ? data.id : 0,
        name_b: data.name_b ? data.name_b : '',
        permissions: data?.permissions ? Object.values(data.permissions) : []
    })

    const validData = validateData(state)

    return (
        <>
            <DefaultModal
                title={
                    `${getTitle(state)} Grupos`
                }
                handleClose={handleClose}
                content={
                    <>
                        <Box className='item-content-modal noborder' >
                            <Box className='item-profile-row'>
                                <TextField
                                    id="name"
                                    type="text"
                                    label="Nome"
                                    value={state.name_b}
                                    onChange={(e) => { setState({ 'name_b': e.target.value }) }}
                                    fullWidth={false}
                                    size='small'
                                    inputProps={{
                                        maxLength: 50
                                    }}
                                />
                            </Box>
                            <FormControl sx={{ minWidth: 120 }} size='small'>
                                <InputLabel id="select-permissions-label">Permissões</InputLabel>
                                <Select
                                    multiple
                                    labelId="select-permissions-label"
                                    id="select-permissions"
                                    label="Permissões"
                                    value={state.permissions}
                                    onChange={(e) => { setState({ 'permissions': e.target.value }) }}
                                    renderValue={(selected) => (
                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                            {permissions.filter(each => selected.includes(each.id)).map((value) => (
                                                <Chip
                                                    deleteIcon={
                                                        <CancelIcon
                                                            onMouseDown={(event) => event.stopPropagation()}
                                                        />
                                                    }
                                                    size='small'
                                                    onDelete={() => setState({ permissions: [...state.permissions.filter(each => each !== value.id)] })}
                                                    key={translate(value.name)}
                                                    label={translate(capitalize(value.name))}
                                                />
                                            ))}
                                        </Box>
                                    )}
                                >
                                    {permissions.filter(permission => !state.permissions.includes(permission.id)).map(each =>
                                        <MenuItem value={each.id}>{translate(capitalize(each.name))}</MenuItem>
                                    )}

                                </Select>
                            </FormControl>

                        </Box>
                    </>
                }
                action={
                    <>
                        <Tooltip placement="left" title={validData}>
                            <Box>
                                <ColorButton
                                    onClick={() => submit(state, reloadData, setLoader, handleClose)}
                                    disabled={validData}
                                >Salvar
                                </ColorButton>
                            </Box>
                        </Tooltip>
                    </>
                }
            />
        </>
    )
}

export default EditGroupModal