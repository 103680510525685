import React, { useEffect, useReducer, useRef, useState } from 'react'
import { lockedReducer } from '../../Utils/reducers'
import DefaultModal from '../../Utils/DefaultModal'
import { handleImageChange } from '../../Utils/imageChange'
import { Box, FormControl, InputLabel, Select, TextField, MenuItem } from '@mui/material'
import { capitalize } from '../../Utils/functions'
import { api } from '../../../services/api'
import { CnpfMask } from '../../Utils/MaskedInput'
import { NumbersOnly } from '../../Utils/IntegerOnly'

export const getRoles = async (setRoles) => {
    const res = await api.get('/member/see_roles/')
    if (res.status === 200){
        setRoles(res.data)
    }
}

const Member = ({ data, parent, setParent, handleClose, profileView }) => {

    const [state, setState] = useReducer(lockedReducer, {
        title: '',
        cpf_cnpj: '',
        team: '',
        signature: '',
        upload_url: null,
        upload_file: null,
        ...data,
        role: data.role || []
    })

    const [roles, setRoles] = useState([])

    const fileRef = useRef()

    useEffect(()=>{
        getRoles(setRoles)
    }, [])

    return (
        <DefaultModal
            handleClose={() => {
                setParent({profile: state})
                handleClose()
            }}
            title={'Dados de membro'}
            content={
                <Box className='item-content-modal noborder' >
                    <Box className='item-profile-row'>
                        <TextField
                            id="title"
                            type="text"
                            label="Título"
                            value={state.title}
                            onChange={(e) => { setState({ 'title': e.target.value }) }}
                            fullWidth={false}
                            size='small'
                            disabled={profileView}
                            inputProps={{
                                maxLength: 50
                            }}
                        />

                        <TextField
                            id="cpf_cnpj"
                            type="text"
                            label="Cpf/Cnpj"
                            size='small'
                            value={state.cpf_cnpj}
                            onChange={(e) => { setState({ 'cpf_cnpj': NumbersOnly(e.target.value) }) }}
                            fullWidth={false}
                            InputProps={{
                                inputComponent: CnpfMask
                            }}
                        />
                    </Box>
                    <Box className='item-profile-row'>
                        <FormControl sx={{ minWidth: 120 }} size='small'>
                            <InputLabel id="select-groups-label">Função</InputLabel>
                            <Select
                                multiple
                                disabled={profileView}
                                labelId="select-groups-label"
                                id="select-groups"
                                label="Função"
                                value={state.role || []}
                                onChange={(e) => { setState({ 'role': e.target.value }) }}
                            >
                                {roles.map((each) =>
                                    <MenuItem value={each.id}>{capitalize(each.name)}</MenuItem>
                                )}

                            </Select>
                        </FormControl>

                        <TextField
                            id="team"
                            type="text"
                            label="Equipe"
                            size='small'
                            disabled
                            defaultValue={state.team}
                            fullWidth={false}
                            inputProps={{
                                maxLength: 50
                            }}
                        />
                    </Box>
                    <Box className='enterprise-logobox' sx={{maxWidth: '100%'}}>
                        <Box className='enterprise-logo-label'>Assinatura</Box>
                        <Box onClick={() => { fileRef.current.click() }} className='enterprise-logo-overlay'>Alterar</Box>
                        <Box className='enterprise-img-container'>
                            <img src={state.upload_url ? state.upload_url : state.signature} alt={state.title}></img>
                        </Box>
                        <input
                            ref={fileRef}
                            style={{ 'display': 'none' }}
                            type='file'
                            onChange={(e) => handleImageChange(e, setState)}
                            accept="image/png, image/jpeg">
                        </input>
                    </Box>
                </Box >
            }
        />
    )
}

export default Member