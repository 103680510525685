import React, { useReducer, useContext, useEffect } from 'react'
import { lockedReducer } from '../../Utils/reducers'
import { api } from '../../../services/api'
import ColorButton from '../../Buttons/ColorButton';
import DefaultModal from '../../Utils/DefaultModal';
import toast from 'react-hot-toast'
import { Box, Tooltip, TextField } from '@mui/material'
import validateData from './validateData';
import { externalComponent } from '../../AppRoutes';
import { NumbersOnly } from '../../Utils/IntegerOnly';
import MegaAutoComplete from '../../Utils/megaautocomplete';
import { NumericFormatInput } from '../../Utils/MaskedInput';
import { MobileDatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { number2dayweek } from '../../Utils/dayjsptbrhelper';
import Divided from '../divided';
import { getSession } from '../../../contexts/auth';

const url = '/toreceive/'


const getTitle = (data) => {
    if (data?.id)
        return 'Editar'
    if (data?.divided) return 'Lançamento misto'
    return 'Adicionar'
}

const submit = async (data, getData, setLoader, handleClose) => {

    setLoader(true)
    let res = null

    if (data.id)
        res = await api.patch(`${url}${data.id}/`, data)
    else
        res = await api.post(url, data)
    if (res.status === 201 || res.status === 200) {
        toast.success(`Conta a receber ${res.status === 201 ? 'adicionado' : 'editado'} com sucesso`)
        getData()
        handleClose()
        setLoader(false)
        return
    }
    toast.error(`Conta a receber não pôde ser ${!data.id ? 'adicionado' : 'editado.'} Motivo: ${res.data}`)
    setLoader(false)
}

const loadRow = async (id, setState, setLoader) => {
    setLoader(true)
    const res = await api.get(url + id + '/')
    if (res.status === 200) {
        setState({ ...res.data, ready: true })
        setLoader(false)
        return
    }
    toast.error(`Conta a pagar não pôde ser recuperada Motivo: ${res.data}`)
    setLoader(false)
}

const EditAReceberModal = ({ data, handleClose, reloadData }) => {

    const integrated = getSession()?.enterprise?.integrated

    const { setLoader } = useContext(externalComponent)

    const [state, setState] = useReducer(lockedReducer, {
        divided: false,
        number: '',
        portion: 1,
        ...(integrated ? { integration_client: '' } : { client: '' }),
        revenue: '',
        costcenter: '',
        emission: '',
        duedate: '',
        value: 0,
        description: '',
        ...data,
        id: data.id ? data.id : 0,
        identifier: data.identifier || '',
        ready: false,
        divided_modal: false
    })

    useEffect(() => {

        if (data.id)
            loadRow(data.id, setState, setLoader)
        else
            setState({ ready: true })

    }, [setLoader, data.id])

    const validData = validateData(state)

    return (
        <>
            {state.divided_modal && <Divided parent={state} setParent={setState} updateUrl={url} reloadData={reloadData} handleClose={handleClose} />}
            {state.ready && <DefaultModal
                title={
                    `${getTitle(state)} Conta a receber`
                }
                handleClose={handleClose}
                content={
                    <>
                        <Box className='item-content-modal noborder' >
                            <Box className='item-profile-row'>
                                {!state.id ? <Box className='user-activity-buttons' sx={{ whiteSpace: 'pre', flex: '0.2 !important' }}>
                                    <ColorButton reverse={state.divided} onClick={() => setState({ divided: false })}>Avulso</ColorButton>
                                    <ColorButton reverse={!state.divided} onClick={() => setState({ divided: true })}>Parcelado</ColorButton>
                                </Box> : null}
                                <TextField
                                    id="number"
                                    type="text"
                                    label="Número"
                                    value={state.number}
                                    onChange={(e) => { setState({ 'number': NumbersOnly(e.target.value, 0) }) }}
                                    sx={{ flex: !state.id ? '0.6 !important' : 1 }}
                                    fullWidth={false}
                                    size='small'
                                    inputProps={{
                                        maxLength: 50
                                    }}
                                />
                                {!state.id ? <TextField
                                    id="parcela"
                                    type="text"
                                    label={state.divided ? "Parcelas" : "Parcela"}
                                    sx={{ flex: '0.2 !important' }}
                                    value={!state.divided ? '1' : state.portion}
                                    onChange={(e) => { setState({ 'portion': NumbersOnly(e.target.value, 0, 999) }) }}
                                    fullWidth={false}
                                    size='small'
                                    inputProps={{
                                        maxLength: 3
                                    }}
                                /> : null}
                            </Box>
                            <Box className='item-profile-row'>
                                <MegaAutoComplete
                                    value={state.client || state.integration_client}
                                    onChange={(data) => {
                                        integrated ? setState({ integration_client: data }):setState({ client: data })
                                    }}
                                    noOptionsText={'Digite para procurar clientes'}
                                    timeoutUrl='/toreceive/people/'
                                    timeoutParam='name'
                                    label='Cliente'
                                />
                            </Box>

                            <Box className='item-profile-row'>
                                <MegaAutoComplete
                                    value={state.revenue}
                                    onChange={(data) => setState({ revenue: data })}
                                    noOptionsText={'Digite para procurar receitas'}
                                    label='Receita'
                                    timeoutUrl='/toreceive/revenue/'
                                    timeoutParam='name'
                                />
                            </Box>
                            <Box className='item-profile-row'>
                                <MegaAutoComplete
                                    value={state.costcenter}
                                    onChange={(data) => setState({ costcenter: data })}
                                    noOptionsText={'Digite para procurar centros de custo'}
                                    timeoutUrl='/toreceive/costcenter/'
                                    timeoutParam='name'
                                    label='Centro de custo'
                                />
                            </Box>
                            <Box className='item-profile-row' >
                                <MobileDatePicker
                                    size='small'
                                    label='Emissão'
                                    value={dayjs(state.emission)}
                                    format='DD/MM/YYYY'
                                    sx={{ flex: 1 }}
                                    onChange={(e) => { setState({ 'emission': e.format('YYYY-MM-DD') }) }}
                                    slotProps={{ textField: { size: 'small' } }}
                                    dayOfWeekFormatter={(n, date) => number2dayweek[date.$W]}
                                    ampm={false}
                                />
                                <MobileDatePicker
                                    size='small'
                                    label={state.divided ? '1° Vencimento' : 'Vencimento'}
                                    value={dayjs(state.duedate)}
                                    format='DD/MM/YYYY'
                                    sx={{ flex: 1 }}
                                    onChange={(e) => { setState({ 'duedate': e.format('YYYY-MM-DD') }) }}
                                    slotProps={{ textField: { size: 'small' } }}
                                    dayOfWeekFormatter={(n, date) => number2dayweek[date.$W]}
                                    ampm={false}
                                />
                                <TextField
                                    id="valor"
                                    type="text"
                                    label="Valor"
                                    value={state.value}
                                    onChange={(e) => { setState({ 'value': e.target.value }) }}
                                    fullWidth={false}
                                    size='small'
                                    InputProps={{
                                        inputComponent: NumericFormatInput
                                    }}
                                />
                            </Box>
                            <Box className='item-profile-row'>
                                <TextField
                                    multiline
                                    maxRows={4}
                                    id="description"
                                    type="text"
                                    label="Observação"
                                    value={state.description}
                                    onChange={(e) => setState({ description: e.target.value })}
                                    fullWidth={true}
                                    size='small'
                                    inputProps={{
                                        maxLength: 5000
                                    }}
                                />
                            </Box>
                        </Box>
                    </>
                }
                action={
                    <>
                        <Tooltip placement="left" title={validData}>
                            <Box>
                                <ColorButton
                                    onClick={() => state.divided ? setState({ divided_modal: true }) : submit(state, reloadData, setLoader, handleClose)}
                                    disabled={validData}
                                >Salvar
                                </ColorButton>
                            </Box>
                        </Tooltip>
                    </>
                }
            />}
        </>
    )
}

export default EditAReceberModal