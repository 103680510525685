import React from 'react'

import { Box, Button } from '@mui/material'

import apptheme from '../theme/default'
import './styles.css'

const DefButton = (props) => {

    const classname = `defbutton ${props.className ? props.className : ''}`
    let backcolor = props.backgroundcolor ? props.backgroundcolor : apptheme.primaryColor
    const color = props.color ? props.color : apptheme.secondaryColor

    const defaultTheme = !props.reversed ? 
    {
        backgroundColor: backcolor,
        color: color,
    } 
    : 
    {
        backgroundColor: color,
        color: backcolor,
    }

    return (
        <>
        <Box sx={defaultTheme} className={classname}>
        <Button {...props} >{props.children}</Button>
        </Box>
        </>
    )
}

export default DefButton

