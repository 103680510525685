import React, { useReducer, useContext } from 'react'
import { lockedReducer } from '../../Utils/reducers'
import { api } from '../../../services/api'
import ColorButton from '../../Buttons/ColorButton';
import DefaultModal from '../../Utils/DefaultModal';
import toast from 'react-hot-toast'
import { Box, Tooltip, TextField, Select, InputLabel, FormControl, Chip, MenuItem } from '@mui/material'
import validateData from './validateData';
import { externalComponent } from '../../AppRoutes';
import { capitalize } from '../../Utils/functions'
import CancelIcon from '@mui/icons-material/Cancel'

const url = '/team/'

const getTitle = (data) => {
    if (data?.id)
        return 'Editar'
    return 'Adicionar'
}

const submit = async (data, getData, setLoader, handleClose) => {

    setLoader(true)
    let res = null

    if (data.id)
        res = await api.patch(`${url}${data.id}/`, data)
    else
        res = await api.post(url, data)
    if (res.status === 201 || res.status === 200) {
        toast.success(`Time ${res.status === 201 ? 'adicionado' : 'editado'} com sucesso`)
        getData()
        handleClose()
        setLoader(false)
        return
    }
    toast.error(`Time não pôde ser ${!data.id ? 'adicionado' : 'editado.'} Motivo: ${res.data}`)
    setLoader(false)
}

const EditTeamModal = ({ data, handleClose, users, reloadData, readOnly }) => {

    const { setLoader } = useContext(externalComponent)

    const [state, setState] = useReducer(lockedReducer, {
        name: '',
        ...data,
        id: data?.id || 0,
        members:  readOnly ? data?.members : data?.members?.map(each => each.id) || []
    })

    const validData = validateData(state)

    const content = (
        <>
            {!readOnly && <Box className='item-profile-row'>
                <TextField
                    id="teamname"
                    type="text"
                    label="Nome"
                    disabled={readOnly}
                    value={state.name}
                    onChange={(e) => { setState({ 'name': e.target.value }) }}
                    fullWidth={false}
                    size='small'
                    inputProps={{
                        maxLength: 50
                    }}
                />
            </Box>}
            <FormControl sx={{ minWidth: 120 }} size='small' >
                <InputLabel id="select-groups-label">Membros</InputLabel>
                <Select
                    multiple
                    labelId="select-groups-label"
                    id="select-groups"
                    disabled={readOnly}
                    label="Membros"
                    value={state.members}
                    onChange={(e) => { setState({ 'members': e.target.value }) }}
                    renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {users ? users.filter(each => selected.includes(each.id)).map((value) => (
                                <Chip
                                    deleteIcon={
                                        <CancelIcon
                                            onMouseDown={(event) => event.stopPropagation()}
                                        />
                                    }
                                    size='small'
                                    onDelete={(e) => { e.preventDefault(); setState({ members: [...state.members.filter(each => each !== value.id)] }) }}
                                    key={value.id}
                                    label={<>{capitalize(value.first_name)} {capitalize(value.last_name)}</>}
                                />
                            )):
                            state.members.map(value => (
                                <Chip size='small' key={value.id} label={<>{capitalize(value.first_name)} {capitalize(value.last_name)}</>}/>
                            ))
                            }
                        </Box>
                    )}
                >
                    {users?.filter(group => !state.members.includes(group.id)).map(each =>
                        <MenuItem value={each.id}>{capitalize(each.first_name)} {capitalize(each.last_name)}</MenuItem>
                    )}

                </Select>
            </FormControl>
        </>
    )

    if (readOnly) return content

    return (
        <>
            <DefaultModal
                title={
                    `${getTitle(state)} Time`
                }
                handleClose={handleClose}
                content={
                    <>
                        <Box className='item-content-modal noborder' >
                            {content}
                        </Box>
                    </>
                }
                action={
                    <>
                        <Tooltip placement="left" title={validData}>
                            <Box>
                                <ColorButton
                                    onClick={() => submit(state, reloadData, setLoader, handleClose)}
                                    disabled={validData}
                                >Salvar
                                </ColorButton>
                            </Box>
                        </Tooltip>
                    </>
                }
            />
        </>
    )
}

export default EditTeamModal