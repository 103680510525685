import { api } from "../../services/api"

let timeout = 0
const timeoutList = (v, endpoint, field, label, setState, value=false) => {
    clearTimeout(timeout)
    timeout = setTimeout(() => { getItemList(v, endpoint, field, label, setState, value) }, 500)
}


const getItemList =async (v, endpoint, field, label, setState, value=false) => {
    setState({smallLoading: true})
    const res = await api.post(endpoint, {search: v}, {timeout: 10000})
    const result = {smallLoading: false}
    if (res.status === 200){
        result[field] = res.data.map((e) => ({label: e[label], id: e.id, ...(value ? {value:e.value} : {}) ,data: e}))
    }
    setState(result)
}

export const timeoutIntegratedList = (v, endpoint, field, label, setState, value=false) => {
    clearTimeout(timeout)
    timeout = setTimeout(() => { getItemIntegratedList(v, endpoint, field, label, setState, value) }, 500)
}

const getItemIntegratedList =async (v, endpoint, field, label, setState, value=false) => {
    setState({smallLoading: true})
    const searchParam = v ? `?${label}=${v}` : ''
    const res = await api.post(endpoint[0], {method: 'GET', path: endpoint[1] + searchParam}, {timeout: 10000})
    const result = {smallLoading: false}
    if (res.status === 200){
        result[field] = (res.data.results || res.data).map((e) => ({label: e[label], id: e.id, ...(value ? {value:e.value} : {}) ,data: e}))
    }
    setState(result)
}

export default timeoutList 