import React, { useReducer, useContext, useEffect } from 'react'
import { lockedReducer } from '../../Utils/reducers'
import { api } from '../../../services/api'
import ColorButton from '../../Buttons/ColorButton';
import DefaultModal from '../../Utils/DefaultModal';
import toast from 'react-hot-toast'
import { Box, Tooltip, TextField, FormControl, InputLabel, Select, MenuItem } from '@mui/material'
import validateData from './validateData';
import { externalComponent } from '../../AppRoutes';
import { MobileDatePicker } from '@mui/x-date-pickers';
import { number2dayweek } from '../../Utils/dayjsptbrhelper';
import { NumericFormatInput } from '../../Utils/MaskedInput';
import dayjs from 'dayjs';
import { movementDict } from '.';
import MegaAutoComplete from '../../Utils/megaautocomplete';

const url = '/cashflow/'

const getTitle = (data) => {
    if (data?.id)
        return 'Editar'
    return 'Adicionar'
}

const submit = async (data, getData, setLoader, handleClose) => {

    setLoader(true)
    let res = null

    if (data.id)
        res = await api.patch(`${url}${data.id}/`, data)
    else
        res = await api.post(url, data)
    if (res.status === 201 || res.status === 200) {
        toast.success(`Fluxo de caixa ${res.status === 201 ? 'adicionado' : 'editado'} com sucesso`)
        getData()
        handleClose()
        setLoader(false)
        return
    }
    toast.error(`Fluxo de caixa não pôde ser ${!data.id ? 'adicionado' : 'editado.'} Motivo: ${res.data}`)
    setLoader(false)
}

const loadRow = async (id, setState, setLoader) => {
    setLoader(true)
    const res = await api.get(url + id + '/')
    if (res.status === 200) {
        setState({ ...res.data, ready: true })
        setLoader(false)
        return
    }
    toast.error(`Conta a pagar não pôde ser recuperada Motivo: ${res.data}`)
    setLoader(false)
}

const EditFluxoCaixaModal = ({ data, handleClose, reloadData }) => {

    const { setLoader } = useContext(externalComponent)

    const [state, setState] = useReducer(lockedReducer, {
        document: '',
        historic: '',
        movement: '',
        date: '',
        value: 0,
        costs: '',
        revenue: '',
        cashaccount: '',
        costcenter: '',
        ...data,
        id: data.id ? data.id : 0,
        ready: false
    })

    useEffect(() => {

        if (data.id)
            loadRow(data.id, setState, setLoader)
        else
            setState({ ready: true })

    }, [setLoader, data.id])

    const validData = validateData(state)

    return (
        <>
            {state.ready &&
                <Box>
                    <DefaultModal
                        title={
                            `${getTitle(state)} Fluxo de caixa`
                        }
                        handleClose={handleClose}
                        content={
                            <>
                                <Box className='item-content-modal noborder' >
                                    <Box className='item-profile-row'>
                                        <TextField
                                            id="historic"
                                            type="text"
                                            label="Histórico"
                                            value={state.historic}
                                            onChange={(e) => { setState({ 'historic': e.target.value }) }}
                                            fullWidth={false}
                                            size='small'
                                            inputProps={{
                                                maxLength: 50
                                            }}
                                        />
                                    </Box>
                                    <Box className='item-profile-row'>
                                        <MegaAutoComplete
                                            value={state.cashaccount}
                                            onChange={(data) => setState({ cashaccount: data })}
                                            noOptionsText={'Digite para procurar contas caixa'}
                                            timeoutUrl={url + 'cashaccounts/'}
                                            timeoutParam='name'
                                            label='Conta caixa'
                                        />
                                        <TextField
                                            id="document"
                                            type="text"
                                            label="Documento"
                                            value={state.document}
                                            onChange={(e) => { setState({ 'document': e.target.value }) }}
                                            fullWidth={false}
                                            size='small'
                                            inputProps={{
                                                maxLength: 50
                                            }}
                                        />
                                    </Box>
                                    <Box className='item-profile-row'>
                                        {!state.id && <FormControl sx={{ minWidth: 200, background: 'white' }} size='small' >
                                            <InputLabel id="select-parcels-label">Movimento</InputLabel>
                                            <Select
                                                labelId="select-parcels-label"
                                                id="select-parcels"
                                                label="Movimento"
                                                value={state.movement}
                                                onChange={(e) => { setState({ movement: e.target.value }) }}
                                            >
                                                <MenuItem value={''}>Selecione *</MenuItem>
                                                {Object.entries(movementDict).map(([key, value]) =>
                                                    <MenuItem value={key}>{value}</MenuItem>
                                                )}
                                            </Select>
                                        </FormControl>}
                                        <MobileDatePicker
                                            size='small'
                                            label='Data'
                                            value={dayjs(state.date)}
                                            format='DD/MM/YYYY'
                                            sx={{ flex: 1 }}
                                            onChange={(e) => { setState({ 'date': e.format('YYYY-MM-DD') }) }}
                                            slotProps={{ textField: { size: 'small' } }}
                                            dayOfWeekFormatter={(n, date) => number2dayweek[date.$W]}
                                            ampm={false}
                                        />
                                        <TextField
                                            id="valor"
                                            type="text"
                                            label="Valor"
                                            value={state.value}
                                            onChange={(e) => { setState({ 'value': e.target.value }) }}
                                            fullWidth={false}
                                            size='small'
                                            InputProps={{
                                                inputComponent: NumericFormatInput
                                            }}
                                        />
                                    </Box>
                                    {state.movement === 'IN' &&
                                        <MegaAutoComplete
                                            value={state.revenue}
                                            onChange={(data) => setState({ revenue: data })}
                                            noOptionsText={'Digite para procurar receitas'}
                                            timeoutUrl={url + 'revenues/'}
                                            timeoutParam='name'
                                            label='Receitas'
                                        />
                                    }
                                    {state.movement === 'OUT' &&
                                        <MegaAutoComplete
                                            value={state.costs}
                                            onChange={(data) => setState({ costs: data })}
                                            noOptionsText={'Digite para procurar despesas'}
                                            timeoutUrl={url + 'costs/'}
                                            timeoutParam='name'
                                            label='Despesas'
                                        />

                                    }
                                    <MegaAutoComplete
                                        value={state.costcenter}
                                        onChange={(data) => setState({ costcenter: data })}
                                        noOptionsText={'Digite para procurar centros de custo'}
                                        timeoutUrl={url + 'costcenter/'}
                                        timeoutParam='name'
                                        label='Centro de custo'
                                    />
                                </Box>
                            </>
                        }
                        action={
                            <>
                                <Tooltip placement="left" title={validData}>
                                    <Box>
                                        <ColorButton
                                            onClick={() => submit(state, reloadData, setLoader, handleClose)}
                                            disabled={validData}
                                        >Salvar
                                        </ColorButton>
                                    </Box>
                                </Tooltip>
                            </>
                        }
                    />
                </Box>
            }
        </>
    )
}

export default EditFluxoCaixaModal