import React, { useEffect, useReducer } from "react"
import { Box, Select, MenuItem, FormControl, InputLabel } from "@mui/material"
import { lockedReducer } from "../../Utils/reducers"
import { capitalize } from "../../Utils/functions"
import { useGridApiContext } from "@mui/x-data-grid"

const operations = {
    'equals': 'Igual',
}

const Internal = ({ filterFields, state, setState, props }) => {
    return (
        <Box sx={{ display: 'flex', gap: '0.5rem', width: '100%' }}>
            <FormControl sx={{ flex: .4 }} size='small' >
                <Select
                    id={`selected-${props.field}-stringfilterfield`}
                    key={`selected-${props.field}-stringfilterfield`}
                    value={state.selector}
                    onChange={(e) => {
                        setState({ 'selector': e.target.value })
                        filterFields[props.field].filterField = state.field
                        filterFields[props.field].selectField = e.target.value
                    }}
                >
                    {Object.entries(operations).map(([key, value]) =>
                        <MenuItem value={key}>{capitalize(value)}</MenuItem>
                    )}
                </Select>
            </FormControl>
            <FormControl sx={{ flex: .6 }} size='small' >
                <InputLabel id={`selected-${props.field}-choicelabel`}>{props.headerName}</InputLabel>
                <Select
                    id={`selected-${props.field}-stringfilterfield`}
                    labelId={`selected-${props.field}-choicelabel`}
                    label={props.headerName}
                    key={`selected-${props.field}-stringfilterfield`}
                    value={state.field}
                    onChange={(e) => {
                        setState({ 'field': e.target.value })
                        filterFields[props.field].filterField = e.target.value
                        filterFields[props.field].selectField = state.selector
                    }}
                >
                    {Object.entries(props.choices || {}).map(([key, value]) =>
                        <MenuItem value={key}>{capitalize(value)}</MenuItem>
                    )}
                </Select>
            </FormControl>
        </Box>
    )
}

export const ChoicesFilterMobile = (props, parent) => {

    const filterFields = parent.filterModel.filterFields


    const [state, setState] = useReducer(lockedReducer, {
        field: filterFields?.[props.field]?.filterField || '',
        selector: filterFields?.[props.field]?.selectField || 'equals'
    })

    useEffect(() => {
        if (filterFields && props.field in filterFields) {
            setState({ field: filterFields[props.field].filterField || '', selector: filterFields[props.field].selectField || 'equals' })
            return
        }
    }, [filterFields, props.field])
    return (
        <Internal filterFields={filterFields} state={state} setState={setState} props={props} />
    )
}

const ChoicesFilter = (props) => {

    const apiRef = useGridApiContext();

    const filter = apiRef.current.state.filter
    const filterFields = filter.filterFields

    const [state, setState] = useReducer(lockedReducer, {
        field: filterFields?.[props.field]?.filterField || '',
        selector: filterFields?.[props.field]?.selectField || 'equals'
    })

    useEffect(() => {
        if (filterFields && props.field in filterFields) {
            setState({ field: filterFields[props.field].filterField || '', selector: filterFields[props.field].selectField || 'equals' })
            return
        }
    }, [filterFields, props.field])

    return (
        <Internal filterFields={filterFields} state={state} setState={setState} props={props} />
    )
}

export default ChoicesFilter