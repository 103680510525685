const IntegerOnly = (value, min, max, returnString=false) => {
    const newvalue = String(value).replace(new RegExp(/[^\d]/, "ig"), "")
    if (returnString) return newvalue
    if (Number(newvalue) < min)
        return Number(min)
    if  (Number(newvalue) > max)
        return Number(max)
    return Number(newvalue)
}

export const FloatOnly = (value, min, max, returnString=false) => {
    const newvalue = value.replace(/[^-\d.]+/g, "")
    return newvalue
}

export const NumbersOnly = (value) => {
    return String(IntegerOnly(value, '', '', true))
}

export default IntegerOnly