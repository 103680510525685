export const createDataFiles = (form) => {
    if ('image' in form){
        const formdata = new FormData()
        const entries = Object.entries(form)
        entries.some(([key, value]) => 
            formdata.append(key, value)
        )
        return(formdata)
    }
        return form
    }

export function capitalize(string) {
    if (string.length === 0) return
    return string[0].toUpperCase() + string.slice(1);
}
export const formatCurrency = (value,lng='pt-BR',currency='BRL') => {
    const moneyFormated = new Intl.NumberFormat(lng,{
        style:'currency',
        currency: currency
    }).format(value)
    return moneyFormated
}