import React, { useContext, useEffect, useState } from 'react'
import ColorButton from '../Buttons/ColorButton'
import apptheme from '../theme/default'
import { Box } from '@mui/material'
import '../Settings/styles.css'
import DefButton from '../Buttons/defbutton'
import ReplyIcon from '@mui/icons-material/Reply';
import { getSession } from '../../contexts/auth'
import { useWindowSize } from "@uidotdev/usehooks";
import { CalcWidthSize } from '../Settings'

import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import PaymentsIcon from '@mui/icons-material/Payments';
import MoveDownIcon from '@mui/icons-material/MoveDown';
import MoveUpIcon from '@mui/icons-material/MoveUp';
import HailIcon from '@mui/icons-material/Hail';
import ContaCaixa from './ContaCaixa'
import CentroCusto from './CentroCusto'
import Despesa from './Despesas'
import Receita from './Receitas'
import Pessoa from './Pessoas'
import { externalComponent } from '../AppRoutes'

const MenuList = () => {

    const user = getSession()
    const permissions = user?.permissions

    return ({
        'cashaccount': {
            title: 'Contas caixa',
            icon: (<AccountBalanceIcon />),
            obj: (<ContaCaixa />),
            permission: (permissions.view_cashaccount)
        },
        'costcenter': {
            title: 'Centros de custo',
            icon: (<PaymentsIcon />),
            obj: (<CentroCusto />),
            permission: permissions.view_costcenter
        },
        'costs': {
            title: 'Despesas',
            icon: (<MoveDownIcon />),
            obj: (<Despesa/>),
            permission: (permissions.view_costs)
        },
        'revenue': {
            title: 'Receitas',
            icon: (<MoveUpIcon />),
            obj: (<Receita/>),
            permission: (permissions.view_revenue)
        },
        'people': {
            title: 'Pessoas',
            icon: (<HailIcon />),
            obj: (<Pessoa/>),
            permission: (permissions.view_people)
        },
    })
}

const Cadastros = () => {
    const {isMobile} = useContext(externalComponent)
    const [selectedTab, setSelectedTab] = useState()
    const size = useWindowSize();

    const menulist = MenuList()

    const calcSize = CalcWidthSize(size)

    const itens = Object.entries(menulist).filter(([key, value]) => value.permission)

    useEffect(()=>{
        window.backtobase = () => setSelectedTab()
    }, [])

    return (
        <>
            {!selectedTab ?
                <>
                    <Box className='gridBox' sx={{ gridTemplateColumns: `repeat(${calcSize}, minMax(0, 1fr))` }}>
                        {itens.map(([key, value]) => (
                            <ColorButton
                                onClick={() => setSelectedTab(key)}
                                sx={{ height: '8rem', flex: 1 , visibility: !value.obj ? 'hidden' : 'visible' }}
                                fColor={apptheme.primaryColor}
                                sColor={apptheme.tertiaryColor}>
                                <Box className='flexbox'>
                                    {value.icon}
                                    {value.title}
                                </Box>
                            </ColorButton>

                        ))}
                    </Box>
                </>
                :
                <>
                    <Box className={`settings-back-button ${isMobile ? '': 'hidden'}`}>
                        <DefButton
                            onClick={() => setSelectedTab()}
                        >
                            <ReplyIcon />
                        </DefButton>
                    </Box>
                    {MenuList()[selectedTab].obj}
                </>
            }

        </>
    )
}

export default Cadastros