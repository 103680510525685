import React, { useState, useEffect } from 'react'
import { Box } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress';
import './styles.css'
import apptheme from '../theme/default';

var interval = ''


const Loader = ({external, color, backgroundColor, className, show}) => {
    const [dots, setDots] = useState(0)

    const initPing = () => {
        setDots(p => {
            if (p > 2)
                return 0
            return p + 1
        })
    }

    useEffect(() => {
        if (interval)
            clearInterval(interval)
        interval = setInterval(initPing, 1000)
    }, [])

    const printDots = (Array(dots).fill('.').join(''))

    const classname = className ? className : ''
    const defcolor = color ? color : apptheme.primaryColor

    return (
        <>
            {show &&
                <Box className={`loader-external ${external ? 'fixed' : ''}`}>
                    <Box className={`loader-default-class ${classname}`}>
                        <CircularProgress sx={{color:defcolor}} />
                        Carregando{printDots}
                    </Box>
                </Box>}
        </>
    )
}

export default Loader