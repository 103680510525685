import { Box } from "@mui/material"

export const number2dayweek = {
    0: 'D',
    1: 'S',
    2: 'T',
    3: 'Q',
    4: 'Q',
    5: 'S',
    6: 'S'
}

export const actionBar = () => {
    return (
        <Box className='action-bar-wrapper'>
            <Box className='action-bar-day'>Dia, mês e ano</Box>
            <Box className='action-bar-hour'>Hora</Box>
        </Box>
    )
}
