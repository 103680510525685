import TextField from '@mui/material/TextField';
import PasswordBtn from './passwordbtn';
import { useContext, useState } from 'react';
import Loader from "../Loader/loader";
import ColorButton from '../Buttons/ColorButton'
import { AuthContext } from '../../contexts/auth'; 
import apptheme from '../theme/default';

const InputsLogin = () => {

    const [loading, setLoading] = useState(false)
    const { login } = useContext
    (AuthContext);
    
    const [username,setUser] = useState("")
    const [password,setPassword] = useState("")

    const submitHandler = async (e) => {
        e.preventDefault()

        setLoading(true)
        await login(username,password)
        setLoading(false)
    }

    const changeUserHandler = (e) => {
        setUser(e.target.value)
    }

    const changePassowrdHandler = (e) => {
        setPassword(e.target.value)
    }

    return(
        <section>
            <Loader external show={loading} />
            <form onSubmit={submitHandler}>
                <div className="items-center">
                    <div>
                        <TextField 
                            id="txtUserName"
                            margin="normal" 
                            required
                            fullWidth
                            label="Email" 
                            name="email"
                            type="text"
                            value={username}
                            onChange={changeUserHandler}
                        />
                    </div>
                    
                    <div className="items-top">
                        <PasswordBtn passProp={password} changePass={changePassowrdHandler}/>
                    </div>
                    
                    <div className="items-top">
                        <ColorButton
                            type="submit" 
                            variant="contained" 
                            sColor={apptheme.tertiaryColor}
                            fullWidth
                            size="medium" 
                            className="mb-3 mb-md-4 md-4"
                            >
                            Entrar
                        </ColorButton>
                    </div>
                    
                </div>
            </form>
        </section>
    )
};

export default InputsLogin;