import { Box } from "@mui/material"
import dayjs from "dayjs"

const validateData = (data) => {

    const response = []

    if (!data?.number)
        response.push(<Box>Digite o número do documento.</Box>)
    if (!data?.portion)
        response.push(<Box>Digite a parcela.</Box>)
    if (!data?.supplier && !data?.integration_supplier)
        response.push(<Box>Selecione o fornecedor.</Box>)
    if (!data?.costs)
        response.push(<Box>Selecione a despesa.</Box>)
    if (!data?.costcenter)
        response.push(<Box>Selecione o centro de custo.</Box>)
    if (!data?.emission)
        response.push(<Box>Selecione a data de emissão.</Box>)
    if (!data?.duedate)
        response.push(<Box>Selecione a data de vencimento.</Box>)
    if (!data?.value)
        response.push(<Box>Digite o valor.</Box>)
    if (data?.value === '0.00')
        response.push(<Box>Valor não pode ser 0</Box>)
    if (data.divided && data?.duedate && dayjs(data.duedate)?.$D > 28)
        response.push(<Box>Data de 1° vencimento não pode ser maior que 28.</Box>)

    if (response.length === 0)
        return ''

    return (response)
}

export const validateQuitatedData = (state, data) => {
    const response = []

    if (!state?.date)
        response.push(<Box>Selecione a data de quitação.</Box>)
    if (!state?.cashaccount)
        response.push(<Box>Selecione a conta caixa.</Box>)
    if (!state?.value)
        response.push(<Box>Digite o valor.</Box>)

    if (response.length === 0)
        return ''

    return (response)
}

export default validateData