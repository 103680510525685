import React, { useContext, useReducer, useEffect } from "react";

import { Box, FormControl, InputLabel, Select, Tooltip, MenuItem } from "@mui/material";
import { api } from "../../../services/api";
import toast from "react-hot-toast";
//import { getSession } from "../../../contexts/auth";
import { lockedReducer } from "../../Utils/reducers";
import { externalComponent } from "../../AppRoutes";
import ColorButton from "../../Buttons/ColorButton";
import { MobileDatePicker } from "@mui/x-date-pickers";
import ReplyIcon from "@mui/icons-material/Reply";
import dayjs from "dayjs";
import { number2dayweek } from "../../Utils/dayjsptbrhelper";
import MegaAutoComplete from "../../Utils/megaautocomplete";
import ReportTable from "../report_table";
import { maskedTable } from "../../Utils/MaskedInput";
import PrintToPDF from "../../Utils/Print2Pdf";

const searchURL = '/reportsaux/'
const url = "/reports/paid/";

const periods = {
  'duedate': 'Vencimento',
  'created_at': 'Emissão',
  'quitated_at': 'Quitação'
}

const filters = {
  'people': 'Fornecedor',
  'city': 'Cidade',
  'estate': 'Estado'
}

const aggregate = {
  ...periods,
  'supplier_pk': 'Fornecedor',
  'value': 'Valor',
  'costs': 'Despesa'
}

const possible_ordering = {
  ...periods,
  'value': 'Valor',
  'costs_name': 'Despesa',
  'supplier_name': 'Fornecedor',
  'costcenter_name': 'Centro de custo'
}

const possible_costs = {
  '': 'Geral',
  'true': 'Despesas fixas',
  'false': 'Despesas variáveis'
}

const generateColumns = (description) => {
  const columns = [
    { field: 'number', headerName: 'Documento', width: '10rem', count: true },
    {
      field: 'created_at', headerName: 'Emissão', width: '7rem',
      valueGetter: ({ value }) => dayjs(value).format('DD/MM/YYYY')
    },
    {
      field: 'duedate', headerName: 'Vencimento', width: '7rem',
      valueGetter: ({ value }) => dayjs(value).format('DD/MM/YYYY')
    },
    {
      field: 'quitated_at', headerName: 'Quitação', width: '7rem',
      valueGetter: ({ value }) => dayjs(value).format('DD/MM/YYYY')
    },
    {
      field: 'paid', headerName: 'Valor', width: '10rem', sum: true,
      valueGetter: ({ value }) => maskedTable.value(value)
    },
    {
      field: 'supplier_name', headerName: 'Fornecedor', width: '10rem'
    },
    {
      field: 'costcenter_name', headerName: 'Centro de custo', width: '10rem'
    },
    {
      field: 'costs_name', headerName: 'Despesa', width: '10rem'
    },
  ]

  if (description)
    columns.push({
      field: 'description', headerName: 'Descrição', width: '10rem'
    },)

  return columns
}

const RenderAutoComplete = ({ renderedField, components }) => {
  return components[renderedField]
}

const generate = async (state, setState, setLoader) => {
  setLoader(true)
  const payload = { costs_id: state.cost?.id, costcenter_id: state.costcenter.id, ordering: state.ordering }
  if (state.period) payload[`${state.period}__gte`] = state.date__gte.format('YYYY-MM-DD')
  if (state.period) payload[`${state.period}__lte`] = state.date__lte.format('YYYY-MM-DD')
  if (state.filter) payload[`${state.filter}`] = state.filter_parameter
  if (state.cost_type) payload.cost_type = state.cost_type === 'true' ? true : false

  toast.success('Seu relatório está sendo gerado, aguarde alguns segundos para o seu processamento')
  const res = await api.post(url, payload)
  if (res.status !== 200) {
    toast.error(`Falha ao gerar o relatório. Motivo: ${res.error}`)
  }
  else {
    const columns = generateColumns(state.description)
    const aggregated_data = res.data
    setState({ rows: aggregated_data, columns: columns })
  }
  setLoader(false)
}

const PaidReport = () => {
  const { setLoader } = useContext(externalComponent);

  const [state, setState] = useReducer(lockedReducer, {
    rows: [],
    columns: [],
    period: '',
    filter: '',
    filter_parameter: '',
    date__gte: dayjs(),
    date__lte: dayjs(),
    filter_id: '',
    cost: '',
    costcenter: '',
    aggregate_by: '',
    description: true,
    ordering: 'created_at',
    cost_type: ''
  });

  useEffect(() => {
    setState({
      date__gte: dayjs().subtract(1, 'month'),
      date__lte: dayjs()
    })
  }, [])

  return (
    <>
      <Box className="information-table">
        <Box className='header'>
          <Box className='left' sx={{ gap: '1rem', display: 'flex', flexDirection: 'column' }}>
            <Box className='item-profile-row' sx={{ gap: '1rem', display: 'flex', flexWrap: 'wrap' }}>
              <Tooltip title='Voltar'>
                <ColorButton
                  className='profile-save-button minimized'
                  onClick={() => window.backtobase()}
                >
                  <ReplyIcon />
                </ColorButton>
              </Tooltip>
              <FormControl size='small' sx={{ minWidth: '10rem', background: 'white' }}>
                <InputLabel id='period-set-data'>Período</InputLabel>
                <Select
                  label='Período'
                  value={state.period}
                  labelId="period-set-data"
                  onChange={(e) => setState({ period: e.target.value })}
                >
                  <MenuItem value=''>Nenhum</MenuItem>
                  {Object.entries(periods).map(([key, value]) => (
                    <MenuItem value={key}>{value}</MenuItem>
                  ))}
                </Select>
              </FormControl>

              <MobileDatePicker
                size='small'
                label='Data de inicio'
                value={dayjs(state.date__gte)}
                format='DD/MM/YYYY'
                sx={{ background: 'white' }}
                onChange={(e) => { setState({ 'date__gte': e }) }}
                slotProps={{ textField: { size: 'small' } }}
                dayOfWeekFormatter={(n, date) => number2dayweek[date.$W]}
                ampm={false}
              />

              <MobileDatePicker
                size='small'
                label='Data de fim'
                value={dayjs(state.date__lte)}
                format='DD/MM/YYYY'
                sx={{ background: 'white' }}
                onChange={(e) => { setState({ 'date__lte': e }) }}
                slotProps={{ textField: { size: 'small' } }}
                dayOfWeekFormatter={(n, date) => number2dayweek[date.$W]}
                ampm={false}
              />
            </Box>
            <Box className='item-profile-row' sx={{ gap: '1rem', display: 'flex', flexWrap: 'wrap' }}>
              <FormControl size='small' sx={{ minWidth: '10rem', background: 'white' }}>
                <InputLabel id='filter-set-data'>Filtro</InputLabel>
                <Select
                  label='Filtro'
                  value={state.filter}
                  labelId="filter-set-data"
                  onChange={(e) => setState({ filter: e.target.value, filter_parameter: '' })}
                >
                  <MenuItem value=''>Nenhum</MenuItem>
                  {Object.entries(filters).map(([key, value]) => (
                    <MenuItem value={key}>{value}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <RenderAutoComplete
                renderedField={state.filter}
                components={{
                  'people': (<MegaAutoComplete
                    key={state.filter}
                    sx={{ flex: 1, maxWidth: '20rem' }}
                    value={state.filter_parameter}
                    onChange={(data) => setState({ filter_parameter: data })}
                    noOptionsText={`Digite para procurar fornecedores`}
                    timeoutUrl={searchURL + 'peoplesupplier/'}
                    timeoutParam={'name'}
                    label={'Fornecedores'}
                  />),
                  'city': (
                    <MegaAutoComplete
                      key={state.filter}
                      sx={{ flex: 1, maxWidth: '20rem' }}
                      value={state.filter_parameter}
                      onChange={(data) => setState({ filter_parameter: data })}
                      noOptionsText={`Digite para procurar Cidades`}
                      timeoutUrl={searchURL + 'citiessupplier/'}
                      timeoutParam={'name'}
                      label={'Cidades'}
                    />
                  ),
                  'estate': (
                    <MegaAutoComplete
                      key={state.filter}
                      sx={{ flex: 1, maxWidth: '20rem' }}
                      value={state.filter_parameter}
                      onChange={(data) => setState({ filter_parameter: data })}
                      noOptionsText={`Digite para procurar Estados`}
                      timeoutUrl={searchURL + 'estatesupplier/'}
                      timeoutParam={'name'}
                      label={'Estados'}
                    />
                  )
                }}
              />
              <MegaAutoComplete
                sx={{ flex: 1, maxWidth: '15rem' }}
                value={state.cost}
                onChange={(data) => setState({ cost: data })}
                noOptionsText={`Digite para procurar Despesas`}
                timeoutUrl={searchURL + 'costs/'}
                timeoutParam={'name'}
                label={'Despesa'}
              />
              <FormControl size='small' sx={{ minWidth: '10rem', background: 'white' }}>
                <InputLabel id='cost_type-set-data'>Tipo de despesa</InputLabel>
                <Select
                  label='Tipo de despesa'
                  value={state.cost_type}
                  labelId="cost_type-set-data"
                  onChange={(e) => setState({ cost_type: e.target.value })}
                >
                  {Object.entries(possible_costs).map(([key, value]) => (
                    <MenuItem value={key}>{value}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <MegaAutoComplete
                sx={{ flex: 1, maxWidth: '15rem' }}
                value={state.costcenter}
                onChange={(data) => setState({ costcenter: data })}
                noOptionsText={`Digite para procurar Centros de custo`}
                timeoutUrl={searchURL + 'costcenter/'}
                timeoutParam={'name'}
                label={'Centro de custo'}
              />
            </Box>
            <Box className='item-profile-row' sx={{ gap: '1rem', display: 'flex', flexWrap: 'wrap' }}>
              <FormControl size='small' sx={{ minWidth: '10rem', background: 'white' }}>
                <InputLabel id='aggregate-set-data'>Agregar</InputLabel>
                <Select
                  label='Agregar'
                  value={state.aggregate_by}
                  labelId="aggregate_by-set-data"
                  onChange={(e) => setState({ aggregate_by: e.target.value })}
                >
                  <MenuItem value=''>Nenhum</MenuItem>
                  {Object.entries(aggregate).map(([key, value]) => (
                    <MenuItem value={key}>{value}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl size='small' sx={{ minWidth: '10rem', background: 'white' }}>
                <InputLabel id='ordenate-set-data'>Ordenar</InputLabel>
                <Select
                  label='Ordenar'
                  value={state.ordering}
                  labelId="ordering-set-data"
                  onChange={(e) => setState({ ordering: e.target.value })}
                >
                  {Object.entries(possible_ordering).map(([key, value]) => (
                    <MenuItem value={key}>{value}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Box className='user-activity-buttons' sx={{ whiteSpace: 'pre', flex: '0.2 !important' }}>
                <ColorButton reverse={!state.description} onClick={() => setState({ description: true })}>Observação</ColorButton>
                <ColorButton reverse={state.description} onClick={() => setState({ description: false })}>S/Obsrvação</ColorButton>
              </Box>
              <ColorButton
                sx={{ height: '2.5rem' }}
                onClick={() => generate(state, setState, setLoader)}
              >Gerar relatório</ColorButton>
              {state.rows.length > 0 &&
                <ColorButton onClick={() => { PrintToPDF('report-main-screen', null, 'Relatório') }}
                sx={{ height: '2.5rem' }}>
                  Imprimir relatório
                </ColorButton>}
            </Box>

          </Box>
        </Box>
        <Box id='report-main-screen' className="report-main-screen">
          <ReportTable
            title={
              <>
                <Box>
                  Relação de contas pagas com {periods[state.period]} entre {state.date__gte.format('DD/MM/YYYY')} e {state.date__lte.format('DD/MM/YYYY')} {state.aggregate_by ? `| Agregado por: ${aggregate[state.aggregate_by]}` : ''}
                </Box>
                <Box>
                  Filtro: {state.filter ? `${filters[state.filter]}: ${state.filter_parameter?.label}` : 'Nenhum'}
                </Box>
              </>
            }
            rows={state.rows}
            columns={state.columns}
            aggregate={state.aggregate_by}
          />
        </Box>
      </Box>
    </>
  );
};

export default PaidReport;
