import { Box } from "@mui/material"

const validateData = (data) => {

    const response = []

    if (data.name_b.length === 0)
        response.push(<Box>Adicione um nome</Box>)

    if (response.length === 0)
        return ''

    return (response)
}






export default validateData