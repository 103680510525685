import React, { useReducer, useRef } from 'react'

import {
    GridToolbarContainer,
    useGridApiContext,
} from '@mui/x-data-grid';
import { Popover, Tooltip } from '@mui/material';
import ColorButton from '../Buttons/ColorButton';
import FilterListIcon from '@mui/icons-material/FilterList';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import { lockedReducer } from '../Utils/reducers';
import { useGridRootProps } from '@mui/x-data-grid';
import Columns from './columns';
import Filters from './filters';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ReplyIcon from '@mui/icons-material/Reply';

const selector = {
    'return' : {function: true, name: '', icon: <Tooltip title='Voltar'><ReplyIcon /></Tooltip>, slot: 'returnComponent', class: 'returnButtonClass', reverse: true},
    'addrow' : { function: true        , name: 'Adicionar', icon: <AddCircleOutlineIcon />, slot: 'addComponent'    },
    'columns': { component: <Columns />, name: 'Colunas'  , icon: <ViewColumnIcon />      , slot: 'columnComponent' },
    'filters': { component: <Filters />, name: 'Filtros'  , icon: <FilterListIcon />      , slot: 'filterComponent' }
}

const TableToolbar = () => {

    const containerRef = useRef()
    const apiRef = useGridApiContext()
    const rootProps = useGridRootProps();

    const [state, setState] = useReducer(lockedReducer, {
        show: null,
        anchorEl: null
    })

    return (
        <>
            <GridToolbarContainer ref={containerRef} sx={{flexWrap: 'nowrap'}}>
                {state.show &&
                    <Popover
                        open={state.show}
                        anchorEl={state.anchorEl}
                        onClose={() => setState({ anchorEl: null, show: null })}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                    >
                        {selector[state.show]?.component}
                    </Popover>
                }

                {Object.entries(selector).filter(([, value]) => rootProps.slots[value.slot]).map(([key, value]) => (
                    <ColorButton 
                    className={value.class || ''}
                    onClick={(e) => {
                        value.function ? rootProps.slots[value.slot]() :
                        setState({ anchorEl: apiRef?.current?.rootElementRef?.current?.children[1]?.children[1] || containerRef.current
                            , show: !state.show ? key : null }) }
                    }
                        startIcon={value.icon}
                        reverse={!value.reverse}>{value.name}</ColorButton >
                ))}
            </GridToolbarContainer>
        </>
    )
}

export default TableToolbar
