import React, { useContext, useMemo, useReducer } from 'react'

import { Box, IconButton, Tooltip } from '@mui/material'
import { api } from '../../../services/api';
import toast from 'react-hot-toast'
import { getSession } from '../../../contexts/auth';
import { lockedReducer } from '../../Utils/reducers';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ConfirmationDialog from '../../Utils/ConfirmationDialog';
import { externalComponent } from '../../AppRoutes';
import EditAPagarModal from './edit';
import Table, { TableHook } from '../../Table';
import dayjs from 'dayjs';
import { maskedTable } from '../../Utils/MaskedInput';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import Quitate from './quitate';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { ResponsiveActionBox } from '../../Table/mobileTable';

const url = '/topay/'

const submitDelete = async (data, setState, getData, setLoader) => {
    setLoader(true)
    const res = await api.delete(`${url}${data.id}/`)
    setLoader(false)
    if (res.status === 204) {
        toast.success('Deletado com sucesso')
        setState({ deleteRow: false })
        getData()
        return
    }
    console.error('Falha ao deletar')

}


const columns = (setState, permissions) => {
    return [
        { field: 'numbermod', headerName: 'N° Documento', flex: 1, filterType: 'string' },
        { field: 'supplier_name', headerName: 'Fornecedor', flex: 1, filterType: 'string' },
        { field: 'costs_name', headerName: 'Despesa', flex: 1, filterType: 'string' },

        {
            field: 'emission', headerName: 'Emissão', flex: 1, filterType: 'daterangeless',
            valueGetter: ({ value }) => dayjs(value).format('DD/MM/YYYY')
        },
        {
            field: 'duedate', headerName: 'Vencimento', flex: 1, filterType: 'daterangeless',
            valueGetter: ({ value }) => dayjs(value).format('DD/MM/YYYY')
        },
        {
            field: 'value', headerName: 'Valor', flex: 1, filterType: 'number',
            valueGetter: ({ value }) => {
                return maskedTable['value'](value)
            }
        },
        {
            field: 'paid', headerName: 'Pago', flex: 1, filterType: 'number',
            valueGetter: ({ value }) => {
                return value ? maskedTable['value'](value) : ''
            }
        },
        {
            field: 'quitated', headerName: 'Quitado', flex: 1, filterType: 'choices', choices: { '1': 'Sim', '0': 'Não' }, hide: true
        },
        {
            field: 'actions', headerName: 'Ações', filterable: false, flex: 1,
            renderCell: (props) => {
                return (
                    <ResponsiveActionBox >
                        {(permissions.change_topay && !props.row.quitated) && <Tooltip title='Quitar documento'><IconButton onClick={() => { setState({ quitate: props.row }) }}><TaskAltIcon /></IconButton></Tooltip>}
                        {(permissions.change_topay && !props.row.quitated) && <Tooltip title='Editar documento'><IconButton onClick={() => { setState({ editRow: props.row }) }}><EditIcon /></IconButton></Tooltip>}
                        {(permissions.view_topay && props.row.quitated) && <Tooltip title='Verificar documento'><IconButton onClick={() => { setState({ quitate: { ...props.row, view_only: true } }) }}><AssignmentIcon /></IconButton></Tooltip>}
                        {permissions.delete_topay && <Tooltip title='Excluir documento'><IconButton onClick={() => { setState({ deleteRow: props.row }) }}><DeleteIcon /></IconButton></Tooltip>}
                    </ResponsiveActionBox>
                )
            }
        }
    ]
}

const APagar = () => {

    const { setLoader } = useContext(externalComponent)

    const user = getSession()

    const [state, setState] = useReducer(lockedReducer, {
        rows: [],
        editRow: null,
        deleteRow: null,
        quitate: null
    })

    const [table, reloadData] = TableHook()

    return (
        <>
            {state.deleteRow &&
                <ConfirmationDialog
                    content={`Deseja excluir a conta a pagar ${state.deleteRow.identifier}?`}
                    handleClose={() => setState({ deleteRow: null })}
                    onConfirm={() => submitDelete(state.deleteRow, setState, reloadData, setLoader)}
                />
            }
            {state.editRow &&
                <EditAPagarModal
                    data={state.editRow}
                    handleClose={() => setState({ editRow: null })}
                    reloadData={reloadData}
                />
            }
            {state.quitate &&
                <Quitate
                    data={state.quitate}
                    handleClose={() => setState({ quitate: null })}
                    reloadData={reloadData}
                />
            }
            <Box className='information-table'>

                <Box className='user-table'>
                    <Table
                        rows={state.rows}
                        columns={useMemo(() => columns(setState, user.permissions), [user.permissions])}
                        pagination
                        density='compact'
                        slots={{
                            addComponent: () => user.permissions.add_topay ? setState({ editRow: {} }) : null,
                            filterComponent: true,
                            columnComponent: true
                        }}
                        toolbar
                        getURL={url}
                        getRowClassName={(params) => `financeirorow--${params.row.quitated}`}
                        updateRows={(rows) => setState({ 'rows': rows })}
                        innerRef={table}
                        mobile={true}
                        mobileProps={
                            {
                                title: ['numbermod'],
                                secondary: [['duedate'], ['value']],
                            }
                        }
                    />
                </Box>
            </Box>
        </>
    )
}

export default APagar