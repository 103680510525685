import React, { useReducer, useContext, useState, useLayoutEffect } from 'react'
import { lockedReducer } from '../../Utils/reducers'
import { api } from '../../../services/api'
import ColorButton from '../../Buttons/ColorButton';
import DefaultModal from '../../Utils/DefaultModal';
import toast from 'react-hot-toast'
import ConfirmationDialog from '../../Utils/ConfirmationDialog';
import { Box, Tooltip, TextField, Select, InputLabel, FormControl, Chip, MenuItem } from '@mui/material'
import validateData from './validateData';
import { externalComponent } from '../../AppRoutes';
import { capitalize } from '../../Utils/functions'
import CancelIcon from '@mui/icons-material/Cancel'
import Member from '../Member';
import uploadfilefunction from '../../Utils/uploadfilefunction';
import { PhoneMask } from '../../Utils/MaskedInput';

const url = '/users/'

export const translate_permissions_keys = {
    'Can': '',
    'add': 'Adicionar',
    'change': 'Alterar',
    'delete': 'Excluir',
    'view': 'Ver'
}

const translate = (string) => {
    let newstring = string
    Object.entries(translate_permissions_keys).forEach(([key, value])=> {newstring = newstring.replace(key, value)})
    return newstring
}

const getTitle = (data) => {
    if (data?.id)
        return 'Editar'
    return 'Adicionar'
}

const passwordReset = async (data, setLoader, handleClose) => {
    setLoader(true)
    const res = await api.post(`${url}${data.id}/reset/`, data)
    setLoader(false)
    if (res.status === 200) {
        toast.success('Senha resetado com sucesso! O usuário receberá a nova senha por e-mail')
        handleClose()
        return
    }
    toast.error('Ocorreu um erro ao resetar a senha do usuário.')
}

const submit = async (data, getData, setLoader, handleClose) => {

    setLoader(true)
    let res = null

    const {profile, ...newData} = data
    const {team, signature, upload_file, upload_url, ...newProfile} = profile
    const payload = {...newData, profile: newProfile}
        
    if (payload.id)
        res = await api.patch(`${url}${payload.id}/`, payload)
    else
        res = await api.post(url, payload)
    if (res.status === 201 || res.status === 200) {
        if (upload_file) 
            await uploadfilefunction(upload_file, `/member/${res.data.id}/uploadsignature/`)
        toast.success(`Usuário ${res.status === 201 ? 'adicionado' : 'editado'} com sucesso`)
        getData()
        handleClose()
        setLoader(false)
        return
    }
    toast.error(`Usuário não pôde ser ${!payload.id ? 'adicionado' : 'editado.'} Motivo: ${res.data}`)
    setLoader(false)
}

const getData = async (id, setState) => {
    if (!id) return
    const res = await api.get(url + id + '/')
    if (res.status === 200){
        setState({
            ...res.data,
            groups: Object.values(res.data.groups),
            permissions: Object.values(res.data.user_only_permissions)
        })
    }
}

const EditUserModal = ({ data, handleClose, groups, permissions, reloadData }) => {

    const { setLoader } = useContext(externalComponent)

    const [state, setState] = useReducer(lockedReducer, {
        id: data.id ? data.id : 0,
        first_name: data.first_name ? data.first_name : '',
        last_name: data.last_name ? data.last_name : '',
        email: data.email ? data.email : '',
        phone_number: data.phone_number ? data.phone_number : '',
        is_active: 'is_active' in data ? data.is_active : true, // pay attention that this valu is boolean but we want it to start as true if is empty
        groups: data?.groups ? Object.values(data.groups) : [],
        permissions: data?.user_only_permissions ? Object.values(data.user_only_permissions) : [],
        profile: data.profile ? data.profile : {}
    })

    const [resetPass, setResetPass] = useState()
    const [showMember, setShowMember] = useState(false)

    const validData = validateData(state)

    useLayoutEffect(()=>{
        getData(data.id, setState)
    }, [data.id])

    return (
        <>
            {showMember &&
                <Member handleClose={() => setShowMember(false)} data={state.profile} parent={state} setParent={setState} />
            }
            {resetPass &&
                <ConfirmationDialog
                    content={`Deseja resetar a senha do usuário ${resetPass.email} - ${resetPass.first_name} ${resetPass.last_name}?`}
                    handleClose={() => setResetPass(null)}
                    onConfirm={() => passwordReset(resetPass, setLoader, () => setResetPass(null))}
                />
            }
            <DefaultModal
                title={
                    `${getTitle(state)} Usuário`
                }
                handleClose={handleClose}
                content={
                    <>
                        <Box className='item-content-modal noborder' >
                            <Box className='user-activity-buttons'>
                                <ColorButton reverse={!state.is_active} onClick={() => { setState({ is_active: true }) }} >Ativo</ColorButton>
                                <ColorButton reverse={state.is_active} onClick={() => { setState({ is_active: false }) }}>Inativo</ColorButton>
                            </Box>
                            <Box className='item-profile-row'>
                                <TextField
                                    id="clientname"
                                    type="text"
                                    label="Nome"
                                    value={state.first_name}
                                    onChange={(e) => { setState({ 'first_name': e.target.value }) }}
                                    fullWidth={false}
                                    size='small'
                                    inputProps={{
                                        maxLength: 50
                                    }}
                                />

                                <TextField
                                    id="clientlastname"
                                    type="text"
                                    label="Sobrenome"
                                    size='small'
                                    value={state.last_name}
                                    onChange={(e) => { setState({ 'last_name': e.target.value }) }}
                                    fullWidth={false}
                                    inputProps={{
                                        maxLength: 50
                                    }}
                                />
                            </Box>

                            <Box className='item-profile-row'>
                                <TextField
                                    id="clientphone"
                                    type="text"
                                    label="Telefone"
                                    size='small'
                                    value={state.phone_number}
                                    onChange={(e) => { setState({ 'phone_number': e.target.value }) }}
                                    fullWidth={false}
                                    InputProps={{
                                        inputComponent: PhoneMask
                                    }}
                                />

                                <TextField
                                    id="clientemail"
                                    type="email"
                                    label="E-mail"
                                    value={state.email}
                                    size='small'
                                    onChange={(e) => { setState({ 'email': e.target.value }) }}
                                    fullWidth={true}
                                    inputProps={{
                                        maxLength: 100
                                    }}
                                />
                            </Box>
                            <ColorButton onClick={() => { setShowMember(true) }} >Ver dados de membro</ColorButton>
                            <FormControl sx={{ minWidth: 120 }} size='small'>
                                <InputLabel id="select-groups-label">Grupos</InputLabel>
                                <Select
                                    multiple
                                    labelId="select-groups-label"
                                    id="select-groups"
                                    label="Grupos"
                                    value={state.groups}
                                    onChange={(e) => { setState({ 'groups': e.target.value }) }}
                                    renderValue={(selected) => (
                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                            {groups.filter(each => selected.includes(each.id)).map((value) => (
                                                <Chip
                                                    deleteIcon={
                                                        <CancelIcon
                                                            onMouseDown={(event) => event.stopPropagation()}
                                                        />
                                                    }
                                                    size='small'
                                                    onDelete={(e) => { e.preventDefault(); setState({ groups: [...state.groups.filter(each => each !== value.id)] }) }}
                                                    key={value.name_b}
                                                    label={capitalize(value.name_b)}
                                                />
                                            ))}
                                        </Box>
                                    )}
                                >
                                    {groups.filter(group => !state.groups.includes(group.id)).map(each =>
                                        <MenuItem value={each.id}>{capitalize(each.name_b)}</MenuItem>
                                    )}

                                </Select>
                            </FormControl>

                            <FormControl sx={{ minWidth: 120 }} size='small'>
                                <InputLabel id="select-permissions-label">Permissões</InputLabel>
                                <Select
                                    multiple
                                    labelId="select-permissions-label"
                                    id="select-permissions"
                                    label="Permissões"
                                    value={state.permissions}
                                    onChange={(e) => { setState({ 'permissions': e.target.value }) }}
                                    renderValue={(selected) => (
                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                            {permissions.filter(each => selected.includes(each.id)).map((value) => (
                                                <Chip
                                                    deleteIcon={
                                                        <CancelIcon
                                                            onMouseDown={(event) => event.stopPropagation()}
                                                        />
                                                    }
                                                    size='small'
                                                    onDelete={() => setState({ permissions: [...state.permissions.filter(each => each !== value.id)] })}
                                                    key={translate(value.name)}
                                                    label={translate(capitalize(value.name))}
                                                />
                                            ))}
                                        </Box>
                                    )}
                                >
                                    {permissions.filter(permission => !state.permissions.includes(permission.id)).map(each =>
                                        <MenuItem value={each.id}>{translate(capitalize(each.name))}</MenuItem>
                                    )}

                                </Select>
                            </FormControl>

                        </Box>
                    </>
                }
                action={
                    <>
                        {state.id ?
                            <Box>
                                <ColorButton
                                    onClick={() => { setResetPass(state) }}>
                                    Resetar senha
                                </ColorButton>
                            </Box>
                            : null
                        }

                        <Tooltip placement="left" title={validData}>
                            <Box>
                                <ColorButton
                                    onClick={() => submit(state, reloadData, setLoader, handleClose)}
                                    disabled={validData}
                                >Salvar
                                </ColorButton>
                            </Box>
                        </Tooltip>
                    </>
                }
            />
        </>
    )
}

export default EditUserModal