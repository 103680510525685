

export const defaultMessages = {
    'pt-BR': "Ocorreu um erro no sistema."
}

const translatedMessages = {
    'pt-BR': {
        'Not found':"Não encontrado",
        'No active account found with the given credentials':"Usuário ou senha incorretos." 
    }
}

export default translatedMessages