import { api } from '../../../services/api';

const Logout = () => {


    sessionStorage.removeItem('user');
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('email');
    api.defaults.headers.Authorization = null;
    window.location.reload()

}


export default Logout