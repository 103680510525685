import { Box } from "@mui/material"

const validateData = (data) => {

    const response = []

    if (!data?.historic?.length > 0)
        response.push(<Box>Digite o histórico.</Box>)
    if (!data?.movement?.length > 0)
        response.push(<Box>Selecione o movimento.</Box>)
    if (!data?.date)
        response.push(<Box>Selecione a data.</Box>)
    if (!data?.value)
        response.push(<Box>Digite o valor.</Box>)
    if (data?.value === '0.00')
        response.push(<Box>Valor não pode ser 0</Box>)
    if (!data?.cashaccount)
        response.push(<Box>Selecione uma conta caixa.</Box>)
    if (!data?.document)
        response.push(<Box>Digite o documento.</Box>)
    if (data.movement === 'IN' && !data.revenue)
        response.push(<Box>Selecione a receita.</Box>)
    if (data.movement === 'OUT' && !data.costs)
        response.push(<Box>Selecione a despesa.</Box>)

    if (response.length === 0)
        return ''

    return (response)
}

export default validateData