import React, { useReducer } from 'react'
import { lockedReducer } from '../Utils/reducers'
import { Box } from '@mui/material'
import ColorButton from '../Buttons/ColorButton'
import './styles.css'
import CallReceivedIcon from '@mui/icons-material/CallReceived';
import CallMadeIcon from '@mui/icons-material/CallMade';

import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import { getSession } from '../../contexts/auth'
import APagar from './APagar'
import AReceber from './AReceber'
import FluxoCaixa from './FluxoCaixa'

const positions = (state, setState) => {
    const user = getSession()
    return ({
        'topay': { name: 'Contas a pagar',  component: (<APagar />), icon: (<CallMadeIcon />), permission: user.permissions.view_topay },
        'toreceive': { name: 'Contas a receber',  component: (<AReceber />), icon: (<CallReceivedIcon />), permission: user.permissions.view_toreceive },
        'cashflow': { name: 'Fluxo de caixa', component: (<FluxoCaixa />), icon: (<CompareArrowsIcon />), permission: user.permissions.view_cashflow }
    })
}

const Finance = () => {

    const [state, setState] = useReducer(lockedReducer, {
        position: 'topay',
        selectedForward: null,
    })
    const positions_object = positions(state, setState)
    const position_list = Object.entries(positions_object)
    return (
        <>

            <Box className='cockpitmenu'>
                <Box className="cockpitbutton">
                    {position_list.map(([key, value]) => (
                        <ColorButton
                            startIcon={value.icon}
                            reverse={key === state.position ? false : true}
                            onClick={() => setState({ position: key })}>{value.name}
                        </ColorButton>
                    ))}
                </Box>
                    <Box sx={{ height: '100%', overflow: 'hidden' }}>
                        {positions_object[state.position]?.component}
                    </Box>
            </Box>

        </>
    )

}
export default Finance