import { api } from "../../services/api"
import toast from "react-hot-toast"
const uploadfilefunction = async (filebinary, url) => {
    if (!filebinary) return
    const formdata = new FormData()
    formdata.append('file', filebinary)
    const res = await api.post(url, formdata)
    if (res.status !== 200)
        toast.error('Falha ao fazer upload do arquivo.')
    return res
}

export default uploadfilefunction