import React, { useState, useCallback, useContext, useReducer, useEffect } from 'react'
import { defaultReducer } from '../../Utils/reducers'
import { externalComponent } from '../../AppRoutes'
import { api } from '../../../services/api'
import { toast } from 'react-hot-toast'
import { Box, TextField } from '@mui/material'
import ColorButton from '../../Buttons/ColorButton'
import SaveIcon from '@mui/icons-material/Save';
import IntegerOnly from '../../Utils/IntegerOnly'
import SendIcon from '@mui/icons-material/Send';
import EmailTestDialog from './EmailTestDialog'
import './styles.css'
import { getSession } from '../../../contexts/auth'
import ReplyIcon from '@mui/icons-material/Reply'

const TlsButton = ({ children, tls, onClick }) => {
    return (
        <ColorButton
            reverse={!tls ? true : false}
            onClick={onClick}>
            {children}
        </ColorButton>
    )
}

const Email = () => {

    const user = getSession()

    const { setLoader, isMobile } = useContext(externalComponent)

    const [showTestDialog, setShowTestDialog] = useState(false)

    const [state, setState] = useReducer(defaultReducer, {
        username: '',
        password: '',
        smtpserver: '',
        smtport: 465,
        smtpporttls: 587,
        tlson: false
    })

    const getEmail = useCallback(async () => {
        setLoader(true)
        const res = await api.get('/email/')
        setLoader(false)
        if (res.status === 200) {
            if (res.data.results.length > 0) {
                const data = res.data.results[0]
                data.password = data.decripted_password
                setState(data)
            }

            return
        }
        toast.error('Ocorreu um erro ao encontrar os dados de email')

    }, [setLoader])

    useEffect(() => {
        getEmail()
    }, [getEmail])

    const saveData = useCallback(async () => {
        setLoader(true)
        let res = null
        if (state.id)
            res = await api.patch(`/email/${state.id}/`, state)
        else
            res = await api.post(`/email/`, state)
        setLoader(false)
        if ([200, 201].includes(res.status)) {
            const data = res.data
            data.password = data.decripted_password
            setState(data)
            toast.success('Dados salvos com sucesso')
            return
        }
        toast.error('Ocorreu um erro ao salvar os dados')
    }, [setLoader, state])


    return (
        <>
            {showTestDialog && <EmailTestDialog object={state} handleClose={() => { setShowTestDialog(false) }} />}
            {state &&
                <>
                    <Box className='email-flex-header' >
                        {!isMobile && <ColorButton
                            className='profile-save-button'
                            onClick={() => window.backtobase()}
                        >
                            <Box className='flexbox'><ReplyIcon /> Voltar</Box>
                        </ColorButton>}
                        <ColorButton
                            className='profile-save-button'
                            onClick={() => saveData(state)}
                            disabled={!user.permissions.change_emails}>
                            <Box className='flexbox'><SaveIcon /> Salvar</Box>
                        </ColorButton>
                        <ColorButton
                            className='profile-save-button'
                            onClick={() => setShowTestDialog(true)}
                            disabled={!user.permissions.change_emails}>
                            <Box className='flexbox'><SendIcon /> Testar</Box>
                        </ColorButton>
                    </Box>
                    <Box className='profile-overflow'>
                        <Box className='item-content-modal item-padding'>
                            <h3>Configurar e-mail</h3>
                            <TextField
                                id="username"
                                type="text"
                                sx={{ backgroundColor: 'white' }}
                                label="Usuário"
                                value={state.username}
                                onChange={(e) => { setState({ 'username': e.target.value }) }}
                                fullWidth={false}
                                size='small'
                                inputProps={{
                                    maxLength: 50
                                }}
                            />

                            <TextField
                                id="senha"
                                type="password"
                                sx={{ backgroundColor: 'white' }}
                                label="Senha"
                                value={state.password}
                                onChange={(e) => { setState({ 'password': e.target.value }) }}
                                fullWidth={false}
                                size='small'
                                inputProps={{
                                    maxLength: 100
                                }}
                            />

                            <TextField
                                id="smtpserver"
                                type="text"
                                sx={{ backgroundColor: 'white' }}
                                label="Servidor"
                                value={state.smtpserver}
                                onChange={(e) => { setState({ 'smtpserver': e.target.value }) }}
                                fullWidth={false}
                                size='small'
                                inputProps={{
                                    maxLength: 100
                                }}
                            />

                            <TextField
                                id="smtport"
                                type="text"
                                sx={{ backgroundColor: 'white' }}
                                label="Porta SMTP"
                                value={state.smtport}
                                onChange={(e) => { setState({ 'smtport': IntegerOnly(e.target.value, 0, 99999) }) }}
                                fullWidth={false}
                                size='small'
                                inputProps={{
                                    maxLength: 5
                                }}
                            />

                            <TextField
                                id="smtpporttls"
                                type="text"
                                sx={{ backgroundColor: 'white' }}
                                label="Porta SMTP Caso TLS"
                                value={state.smtpporttls}
                                onChange={(e) => { setState({ 'smtpporttls': IntegerOnly(e.target.value, 0, 99999) }) }}
                                fullWidth={false}
                                size='small'
                                inputProps={{
                                    maxLength: 5
                                }}
                            />

                            <Box className='email-tls-buttons'>
                                <TlsButton tls={!state.tlson} onClick={() => { setState({ tlson: false }) }}>Sem TLS</TlsButton>
                                <TlsButton tls={state.tlson} onClick={() => { setState({ tlson: true }) }} >TLS</TlsButton>
                            </Box>
                        </Box>
                    </Box>
                </>}
        </>
    )
}

export default Email