import { Box } from "@mui/material"

const validateData = (data) => {

    const response = []

    if (!data?.first_name?.length > 0)
        response.push(<Box>Digite o nome do usuário.</Box>)
    if (!data?.email?.length > 0)
        response.push(<Box>Digite o email do usuário.</Box>)
    if (!data?.email?.includes('@'))
        response.push(<Box>Email não contém caracter @.</Box>)
    if (data?.groups?.length === 0)
        response.push(<Box>Selecione um grupo.</Box>)

    if (response.length === 0)
        return ''

    return (response)
}






export default validateData