import { Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton } from '@mui/material'
import React from 'react'
import CloseIcon from '@mui/icons-material/Close'
import './styles.css'

const DefaultModal = ({ title, content, action, handleClose, dialogProps }) => {

    return (
        <>
            <Dialog
                open={true}
                fullWidth={true}
                {...dialogProps}
                className='item-dialog'>
                {title &&
                    <>
                        <DialogTitle className='default-dialog'>
                            <span>{title}</span><span><IconButton><CloseIcon onClick={handleClose} /></IconButton></span>
                        </DialogTitle>
                        <Divider />
                    </>
                }
                {content && <DialogContent className='default-dialog-content'>
                    {content}
                </DialogContent>
                }
                {action &&
                    <DialogActions sx={{ gap: "1rem", padding: '0rem 1.5rem 1rem 1.5rem' }}>
                        {action}
                    </DialogActions>
                }
            </Dialog>
        </>
    )

}

export default DefaultModal