
const PrintToPDF = (container_name = '', ref = null) => {
    const { jsPDF } = window.jspdf;
    const doc = new jsPDF();
    

    let position = 0;
    const input = ref || document.getElementById(container_name); // Replace 'container' with your container's ID

    const tempDiv = document.createElement('div')

    tempDiv.innerHTML =`
        <html>
            <head>
                <title>Relatório</title>
            </head>
            <body>
                ${input.outerHTML}
            </body>
        </html>
        `

    document.body.appendChild(tempDiv)
    
    window.html2canvas(tempDiv).then(canvas => {
        const imgWidth = 210;
        const pageHeight = 297;
        const imgHeight = canvas.height * imgWidth / canvas.width;
        let heightLeft = imgHeight;
        const imgData = canvas.toDataURL("image/png");
        doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;

        // Add new pages if necessary
        while (heightLeft >= 0) {
            position = heightLeft - imgHeight;
            doc.addPage();
            doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;
        }

        doc.save("relatório.pdf");
    });

    document.body.removeChild(tempDiv);

};

export default PrintToPDF;