import React, { useContext, useReducer, useMemo, useEffect } from 'react'

import { Box, IconButton, Tooltip } from '@mui/material'
import { api } from '../../../services/api';
import toast from 'react-hot-toast'
import { getSession } from '../../../contexts/auth';
import { lockedReducer } from '../../Utils/reducers';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ConfirmationDialog from '../../Utils/ConfirmationDialog';
import { externalComponent } from '../../AppRoutes';
import EditPessoaModal from './edit';
import { maskedTable } from '../../Utils/MaskedInput';
import Table, { TableHook } from '../../Table';
import { PhoneMask, CnpfMask } from '../../Utils/MaskedInput';
import { ResponsiveActionBox } from '../../Table/mobileTable';

const url = '/people/'

const submitDelete = async (data, setState, getData, setLoader) => {
    setLoader(true)
    const res = await api.delete(`${url}${data.id}/`)
    setLoader(false)
    if (res.status === 204) {
        toast.success('Deletado com sucesso')
        setState({ deleteRow: false })
        getData()
        return
    }
    console.error('Falha ao deletar')

}

const getTypes = async (setTypes) => {
    const res = await api.get(url + 'types/')
    if (res.status === 200){
        setTypes({types:res.data})
        return
    }
    toast.error('Tipos não encontrados.')
}

const columns = (types, setState, permissions) => {
    return [
        { field: 'identifier', headerName: 'Identificador', flex: 1, filterType: 'number' },
        { field: 'name', headerName: 'Nome', flex: 1, filterType: 'string' },
        { field: 'email', headerName: 'E-mail', flex: 1, filterType: 'string', },
        {
            field: 'phone_number', headerName: 'Telefone', flex: 1, filterType: 'string', filterMask: PhoneMask,
            valueGetter: (value) => {
                return maskedTable['phone'](value)
            }
        },
        {
            field: 'cpf_cnpj', headerName: 'CPF/CNPJ', flex: 1, filterType: 'string', filterMask: CnpfMask,
            valueGetter: (value) => {
                return maskedTable['cpfcnpj'](value)
            }
        },
        {
            field: 'type', headerName: 'Tipo', flex: 1, filterType: 'choices', choices:Object.fromEntries(types),
            renderCell: ({row}) => {
                return row.type_translated.substring(0,1)
            }
        },
        {
            field: 'actions', headerName: 'Ações', filterable: false,
            renderCell: (props) => {
                return (
                    <ResponsiveActionBox >
                        {permissions.change_people && <Tooltip title='Editar'> <IconButton onClick={() => { setState({ editRow: props.row }) }}><EditIcon /></IconButton></Tooltip>}
                        {permissions.delete_people && <Tooltip title='Excluir'><IconButton onClick={() => { setState({ deleteRow: props.row }) }}><DeleteIcon /></IconButton></Tooltip>}
                    </ResponsiveActionBox>
                )
            }
        }
    ]
}


const Pessoa = () => {

    const { setLoader } = useContext(externalComponent)

    const user = getSession()

    const [state, setState] = useReducer(lockedReducer, {
        rows: [],
        editRow: null,
        deleteRow: null,
        types: []
    })

    const [table, reloadData] = TableHook()

    useEffect(()=>{
        getTypes(setState)
    }, [])

    return (
        <>
            {state.deleteRow &&
                <ConfirmationDialog
                    content={`Deseja excluir a pessoa ${state.deleteRow.name} - ${state.deleteRow.email}?`}
                    handleClose={() => setState({ deleteRow: null })}
                    onConfirm={() => submitDelete(state.deleteRow, setState, reloadData, setLoader)}
                />
            }
            {state.editRow &&
                <EditPessoaModal
                    data={state.editRow}
                    handleClose={() => setState({ editRow: null })}
                    reloadData={reloadData}
                    types={state.types}
                />
            }
            <Box className='information-table'>
                <Box className='user-table'>
                    <Table
                        rows={state.rows}
                        columns={useMemo(() => columns(state.types, setState, user.permissions), [state.types, user.permissions])}
                        pagination
                        density='compact'
                        toolbar
                        slots={{
                            returnComponent: ()=> window.backtobase(),
                            addComponent: () => user.permissions.add_people ? setState({ editRow: {} }) : null,
                            filterComponent: true,
                            columnComponent: true
                        }}
                        getURL={url}
                        updateRows={(rows) => setState({ 'rows': rows })}
                        innerRef={table}
                        mobile={true}
                        mobileProps={
                            {
                                title: ['identifier'],
                                secondary: [['name'], ['created_at']],
                            }
                        }
                    />
                </Box>
            </Box>
        </>
    )
}

export default Pessoa