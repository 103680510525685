import React from 'react'
import { NumericFormat, numericFormatter } from 'react-number-format';
import { IMaskInput, IMask } from "react-imask";


const Masks = {
  phonemask : '(00) 00000-0000',
  cep: '00000-000'
}

window.test = numericFormatter

const MasksDict = ( ) => {
  return {
    'phone': (data) => {const masked = IMask.createMask({ mask: Masks.phonemask}); masked?.resolve(String(data?.value)); return masked?.value},
    'cpfcnpj': (data) => {const masked = IMask.createMask({ mask: data?.value?.length > 11 ? '00.000.000/0000-00' : '000.000.000-000'}); masked?.resolve(String(data?.value)); return masked?.value},
    'value' : (data) =>  {return numericFormatter(String(data), { prefix: 'R$ ', thousandSeparator: ".", decimalSeparator: ",", decimalScale: 2, fixedDecimalScale: true});}
    
  }
}

export const maskedTable = MasksDict()

export const PhoneMask = React.forwardRef((props, ref) => {
  return (
      <IMaskInput
          {...props}
          mask={Masks.phonemask}
          inputRef={ref}
          onAccept={(value) => props.onChange({target: {value}})}
          overwrite
      />
  )
})

export const CepMask = React.forwardRef((props, ref) => {
  return (
      <IMaskInput
          {...props}
          mask={"00000-000"}
          inputRef={ref}
          onAccept={(value) => props.onChange({target: {value}})}
      />
  ) 
})

export const CnpfMask = React.forwardRef((props, ref) => {
  return (
      <IMaskInput
          {...props}
          mask={[
              {
                  mask: props?.value?.length > 11 ? '00.000.000/0000-00' : '000.000.000-000'
              }
          ]}
          inputRef={ref}
          unmask={true}
          //onAccept={(value) => props.onChange({target: {value:value}})}
          overwrite
      />
  ) 
})

export const NumericFormatInput = React.forwardRef(function NumericFormatCustom(
    props,
    ref,
) {
    const {onChange, ...other } = props;

    return (
        <NumericFormat
            {...other}
            getInputRef={ref}
            prefix={'R$ '}
            thousandSeparator="."
            decimalSeparator=","
            decimalScale={2}
            fixedDecimalScale
            valueIsNumericString
            onValueChange={(values) => {
                onChange({
                  target: {
                    name: props.name,
                    value: values.value,
                  },
                });
              }}
        />
    )
}
)


/* export const PhoneInput = React.forwardRef(function NumericFormatCustom(
    props,
    ref,
) {
    const {onChange, ...other } = props;

    return (
        <PatternFormat 
            {...other}
            getInputRef={ref}
            format="## (##) #####-####" 
            allowEmptyFormatting
            onValueChange={(values) => {
                onChange({
                  target: {
                    name: props.name,
                    value: values.value,
                  },
                });
              }}
        />
    )
}
) */


/* export const CepInput = React.forwardRef(function NumericFormatCustom(
  props,
  ref,
) {
  const {onChange, ...other } = props;

  return (
      <PatternFormat 
          {...other}
          getInputRef={ref}
          format="#####-###" 
          onValueChange={(values) => {
              onChange({
                target: {
                  name: props.name,
                  value: values.value,
                },
              });
            }}
      />
  )
}
) */

