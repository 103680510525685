import React, { useReducer, useContext, useEffect } from 'react'
import { lockedReducer } from '../../Utils/reducers'
import { api } from '../../../services/api'
import ColorButton from '../../Buttons/ColorButton';
import DefaultModal from '../../Utils/DefaultModal';
import toast from 'react-hot-toast'
import { Box, Tooltip, TextField } from '@mui/material'
import { validateQuitatedData } from './validateData';
import { externalComponent } from '../../AppRoutes';
import MegaAutoComplete from '../../Utils/megaautocomplete';
import { NumericFormatInput } from '../../Utils/MaskedInput';
import { MobileDatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { number2dayweek } from '../../Utils/dayjsptbrhelper';
import ConfirmationDialog from '../../Utils/ConfirmationDialog';
import { maskedTable } from '../../Utils/MaskedInput';


const url = '/toreceive/'

const submit = async (data, getData, setLoader, handleClose) => {

    setLoader(true)
    let res = null
    res = await api.post(`${url}${data.id}/quitate/`, data)
    if (res.status === 201 || res.status === 200) {
        toast.success(`Conta a receber quitada com sucesso`)
        getData()
        handleClose()
        setLoader(false)
        return
    }
    toast.error(`Conta a receber não pôde ser quitada Motivo: ${res.data}`)
    setLoader(false)
}

const loadData = async (data, setState, setLoader) => {
    setLoader(true)
    const res = await api.get(`${url}${data.id}/`)
    if (res.status === 200)
        setState({ ...res.data.cashflow, ready: true })
    setLoader(false)
    if (res.status !== 200)
        toast.error('Falha ao carregar os dados.')
}

const Quitate = ({ data, handleClose, reloadData }) => {

    const { setLoader } = useContext(externalComponent)

    const [state, setState] = useReducer(lockedReducer, {
        divided: false,
        historic: '',
        date: '',
        value: 0,
        cashaccount: '',
        additional: 0,
        discount: 0,
        ...data,
        id: data.id ? data.id : 0,
        identifier: data.identifier || '',
        ready: true,
        confirmSubmit: null
    })

    const validData = validateQuitatedData(state, data)

    useEffect(() => {
        if (data.view_only)
            loadData(data, setState, setLoader)
    }, [data, setLoader])

    return (
        <>
            {state.confirmSubmit &&
                <ConfirmationDialog
                    title={state.confirmSubmit.title}
                    handleClose={() => setState({ confirmSubmit: null })}
                    onConfirm={state.confirmSubmit.function}
                    okButton={state.confirmSubmit.okButton}
                    content={state.confirmSubmit.content}
                    message={state.confirmSubmit.message}
                />}
            {state.ready && <DefaultModal

                title={
                    `${state.view_only ? 'Ver documento' : 'Quitar conta a receber'}`
                }
                handleClose={handleClose}
                content={
                    <>
                        <Box className='item-content-modal noborder' >
                            {state.view_only && <Box className='item-profile-row'>{state.historic}</Box>}
                            <Box className='item-profile-row'>
                                <MobileDatePicker
                                    size='small'
                                    label='Data'
                                    value={dayjs(state.date)}
                                    format='DD/MM/YYYY'
                                    disabled={data.view_only}
                                    sx={{ flex: 1 }}
                                    onChange={(e) => { setState({ 'date': e.format('YYYY-MM-DD') }) }}
                                    slotProps={{ textField: { size: 'small' } }}
                                    dayOfWeekFormatter={(n, date) => number2dayweek[date.$W]}
                                    ampm={false}
                                />
                                <MegaAutoComplete
                                    value={state.cashaccount}
                                    onChange={(data) => setState({ cashaccount: data })}
                                    noOptionsText={'Digite para procurar contas caixa'}
                                    disabled={data.view_only}
                                    timeoutUrl={url + 'cashaccounts/'}
                                    timeoutParam='name'
                                    label='Conta caixa'
                                />
                            </Box>
                            <Box className='item-profile-row'>
                                <TextField
                                    id="additional"
                                    type="text"
                                    label="Adicional"
                                    value={state.additional}
                                    disabled={data.view_only}
                                    onChange={(e) => { setState({ 'additional': e.target.value }) }}
                                    fullWidth={false}
                                    size='small'
                                    InputProps={{
                                        inputComponent: NumericFormatInput
                                    }}
                                />
                                <TextField
                                    id="discount"
                                    type="text"
                                    label="Desconto"
                                    value={state.discount}
                                    disabled={data.view_only}
                                    onChange={(e) => { setState({ 'discount': e.target.value }) }}
                                    fullWidth={false}
                                    size='small'
                                    InputProps={{
                                        inputComponent: NumericFormatInput
                                    }}
                                />
                            </Box>
                            {data.view_only ?
                                <Box sx={{ textAlignLast: 'end' }}>Valor final: {maskedTable['value'](Number(state.value))}</Box>
                                :
                                <Box sx={{ textAlignLast: 'end' }}>Valor final: {maskedTable['value'](Number(state.value) + Number(state.additional) - Number(state.discount))}</Box>
                            } </Box>
                    </>
                }
                action={state.view_only ? null : (
                    <>
                        <Tooltip placement="left" title={validData}>
                            <Box>
                                <ColorButton
                                    onClick={() => setState({
                                        confirmSubmit: {
                                            function: () => submit(state, reloadData, setLoader, handleClose),
                                            title: 'Confirmar ação.',
                                            content: 'Deseja quitar a conta?',
                                            okButton: 'Sim'
                                        }
                                    })}
                                    disabled={validData}
                                >Quitar
                                </ColorButton>
                            </Box>
                        </Tooltip>
                    </>
                )





                }
            />}
        </>
    )
}

export default Quitate