import React, {useReducer} from 'react'
import { Autocomplete, TextField } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress';
import timeoutList, { timeoutIntegratedList } from './TimeoutList';
import { lockedReducer } from './reducers';

const MegaAutoComplete = ({multiple=false, value, onChange, noOptionsText, timeoutUrl, timeoutParam, label, integrated=false, integrated_path=false, ...replace}) => {

    const [state, setState] = useReducer(lockedReducer,{
        data: [],
        smallLoading: false
    })

    const timeoutFunc = integrated ? timeoutIntegratedList : timeoutList
    const urlPath     = integrated ? [integrated_path, timeoutUrl] : timeoutUrl

    return (
        <Autocomplete
            multiple={multiple}
            sx={{ flex: 1 }}
            id={'autocompletebox-plagues'}
            value={value}
            options={state.data.filter(each => multiple ? !value?.map(e => e.id).includes(each.id) : value?.id !== each.id)}
            onChange={(e, selectedData) => onChange(selectedData)}
            autoHighlight
            handleHomeEndKeys
            clearText='Limpar'
            noOptionsText={noOptionsText || 'Digite para pesquisar'}
            loading={state.smallLoading}
            ChipProps={{ size: 'small' }}
            filterSelectedOptions
            onOpen={() => state.data.length === 0 && timeoutFunc('', urlPath, 'data', timeoutParam || 'name', setState)}
            onInputChange={(e, v) => { e?.type === 'change' && timeoutFunc(v, urlPath, 'data', timeoutParam || 'name', setState) }}
            renderInput={(params) =>
                <TextField
                    {...params}
                    id={'autocompleteinput'}
                    type="text"
                    sx={{ backgroundColor: 'white' }}
                    label={label || ''}
                    size="small"
                    fullWidth={true}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {state.smallLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            }
            {...replace}
        />
    )
}

export default MegaAutoComplete