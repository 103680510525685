import React, { useContext, useMemo, useReducer } from 'react'

import { Box, IconButton, Tooltip } from '@mui/material'
import { api } from '../../../services/api';
import toast from 'react-hot-toast'
import { getSession } from '../../../contexts/auth';
import { lockedReducer } from '../../Utils/reducers';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ConfirmationDialog from '../../Utils/ConfirmationDialog';
import { externalComponent } from '../../AppRoutes';
import EditFluxoCaixaModal from './edit';
import Table, { TableHook } from '../../Table';
import dayjs from 'dayjs';
import { maskedTable } from '../../Utils/MaskedInput';
import MegaAutoComplete from '../../Utils/megaautocomplete';
import { number2dayweek } from '../../Utils/dayjsptbrhelper';
import { MobileDatePicker } from '@mui/x-date-pickers';
import ColorButton from '../../Buttons/ColorButton';
import { ResponsiveActionBox } from '../../Table/mobileTable';

const url = '/cashflow/'

export const movementDict = { 'IN': 'ENTRADA', 'OUT': 'SAÍDA' }

const submitDelete = async (data, setState, getData, setLoader) => {
    setLoader(true)
    const res = await api.delete(`${url}${data.id}/`)
    setLoader(false)
    if (res.status === 204) {
        toast.success('Deletado com sucesso')
        setState({ deleteRow: false })
        getData()
        return
    }
    console.error('Falha ao deletar')

}

const columns = (setState, permissions) => {
    return [
        {
            field: 'cashaccount_name', headerName: 'Conta Caixa', flex: 1, filterType: 'stringlist',
            search_url: url + 'cashaccounts/', search_result: 'name', filterable: false
        },
        { field: 'document', headerName: 'Documento', flex: 1, filterType: 'string' },
        { field: 'historic', headerName: 'Histórico', flex: 1, filterType: 'string' },
        {
            field: 'movement', headerName: 'Movimento', flex: 1, filterType: 'choices', choices: movementDict,
            valueGetter: ({ value }) => movementDict[value]
        },
        { field: 'cost_revenue', headerName: 'Despesa/Receita', flex: 1, filterType: 'string' },

        {
            field: 'date', headerName: 'Data/Hora', flex: 1, filterType: 'daterangeless', filterable: false,
            valueGetter: ({ value }) => dayjs(value).format('DD/MM/YYYY')
        },
        {
            field: 'value', headerName: 'Valor', flex: 1, filterType: 'number',
            valueGetter: ({ value }) => {
                return maskedTable['value'](value)
            }
        },
        {
            field: 'actions', headerName: 'Ações', filterable: false,
            renderCell: (props) => {
                return (
                    <ResponsiveActionBox >
                        {permissions.change_cashflow && <Tooltip title='Editar fluxo de caixa'><IconButton onClick={() => { setState({ editRow: props.row }) }}><EditIcon /></IconButton></Tooltip>}
                        {permissions.delete_cashflow && <Tooltip title='Excluir fluxo de caixa'><IconButton onClick={() => { setState({ deleteRow: props.row }) }}><DeleteIcon /></IconButton></Tooltip>}
                    </ResponsiveActionBox>
                )
            }
        }
    ]
}

const calcFilters = (state) => {
    const returnFilter = {}
    if (state.cashaccount)
        returnFilter['cashaccount_name'] = state.cashaccount.label
    if (state.dateini)
        returnFilter['date__gte'] = state.dateini
    if (state.dateend)
        returnFilter['date__lte'] = state.dateend
    return returnFilter
}

const FluxoCaixa = () => {

    const { setLoader } = useContext(externalComponent)

    const user = getSession()

    const [state, setState] = useReducer(lockedReducer, {
        rows: [],
        editRow: null,
        deleteRow: null,
        cashaccount: '',
        dateini: '',
        dateend: ''
    })

    const [table, reloadData] = TableHook()

    return (
        <>
            {state.deleteRow &&
                <ConfirmationDialog
                    content={`Deseja excluir o fluxo de caixa ${state.deleteRow.identifier}?`}
                    handleClose={() => setState({ deleteRow: null })}
                    onConfirm={() => submitDelete(state.deleteRow, setState, reloadData, setLoader)}
                />
            }
            {state.editRow &&
                <EditFluxoCaixaModal
                    data={state.editRow}
                    handleClose={() => setState({ editRow: null })}
                    reloadData={reloadData}
                />
            }
            <Box className='information-table'>
            <Box className='filter-headers'>
                        <MegaAutoComplete
                            value={state.cashaccount}
                            onChange={(data) => {
                                setState({ cashaccount: data })
                            }}
                            noOptionsText={`Digite para procurar Contas caixa`}
                            timeoutUrl={url + 'cashaccounts/'}
                            timeoutParam='name'
                            label='Conta caixa'
                        />
                        <MobileDatePicker
                            size='small'
                            label='Data'
                            value={dayjs(state.dateini)}
                            format='DD/MM/YYYY'
                            sx={{ flex: 1, background: 'white' }}
                            onChange={(e) => { setState({ 'dateini': e.format('YYYY-MM-DD') }) }}
                            slotProps={{ textField: { size: 'small' } }}
                            dayOfWeekFormatter={(n, date) => number2dayweek[date.$W]}
                            ampm={false}
                        />
                        <MobileDatePicker
                            size='small'
                            label='Data'
                            value={dayjs(state.dateend)}
                            format='DD/MM/YYYY'
                            sx={{ flex: 1, background: 'white' }}
                            onChange={(e) => { setState({ 'dateend': e.format('YYYY-MM-DD') }) }}
                            slotProps={{ textField: { size: 'small' } }}
                            dayOfWeekFormatter={(n, date) => number2dayweek[date.$W]}
                            ampm={false}
                        />
                        <ColorButton
                            sx={{ height: '2.5rem' }}
                            onClick={() => {
                                const newCustomItems = {...table.apiRef.current.state.filter.filterModel.customItems, ...calcFilters(state)}
                                if (!state.cashaccount) delete newCustomItems.cashaccount_name
                                if (!state.dateini) delete newCustomItems.dateini
                                if (!state.dateend) delete newCustomItems.dateend
                                table.apiRef.current.setFilterModel(
                                    {...table.apiRef.current.state.filter.filterModel
                                        , customItems: newCustomItems})
                            }}
                        >Pesquisar</ColorButton>
                    </Box>
                <Box className='user-table'>
                    <Table
                        rows={state.rows}
                        columns={useMemo(() => columns(setState, user.permissions), [user.permissions])}
                        pagination
                        density='compact'
                        slots={{
                            addComponent: () => user.permissions.add_cashflow ? setState({ editRow: {} }) : null,
                            filterComponent: true,
                            columnComponent: true
                        }}
                        toolbar
                        getRowClassName={(params) => `financeirorow--${params.row.movement}`}
                        getURL={url}
                        updateRows={(rows) => setState({ 'rows': rows })}
                        innerRef={table}
                        mobile={true}
                        mobileProps={
                            {
                                title: ['document'],
                                secondary: [['movement', 'cost_revenue'], ['date', 'value'], ['historic']],
                            }
                        }
                    />
                </Box>
            </Box>
        </>
    )
}

export default FluxoCaixa