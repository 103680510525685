import React, { useEffect, useReducer } from "react"
import { Box } from "@mui/material"
import { lockedReducer } from "../../Utils/reducers"
import { useGridApiContext } from "@mui/x-data-grid"
import { MobileDatePicker } from "@mui/x-date-pickers"
import dayjs from "dayjs"
import { number2dayweek } from "../../Utils/dayjsptbrhelper"

const Internal = ({ filterFields, state, setState, props }) => {
    return (
        <Box sx={{ display: 'flex', gap: '0.5rem' }}>
            <MobileDatePicker
                size='small'
                label={`De ${props.headerName}`}
                value={dayjs(state.dateini)}
                format='DD/MM/YYYY'
                sx={{ background: 'white', flex: .5 }}
                dayOfWeekFormatter={(n, date) => number2dayweek[date.$W]}
                onChange={(e) => {
                    const value = e.format('YYYY-MM-DD')
                    setState({ dateini: value })
                    filterFields[`${props.field}__gte`].filterField = value
                    filterFields[`${props.field}__lte`].filterField = state.dateend
                }}
                slotProps={{ textField: { size: 'small' } }}
                ampm={false}
            />
            <MobileDatePicker
                size='small'
                label={`Até ${props.headerName}`}
                value={dayjs(state.dateend)}
                format='DD/MM/YYYY'
                sx={{ background: 'white', flex: .5 }}
                dayOfWeekFormatter={(n, date) => number2dayweek[date.$W]}
                onChange={(e) => {
                    const value = e.format('YYYY-MM-DD')
                    setState({ dateend: value })
                    filterFields[`${props.field}__gte`].filterField = state.dateini
                    filterFields[`${props.field}__lte`].filterField = value
                }}
                slotProps={{ textField: { size: 'small' } }}
                ampm={false}
            />
        </Box>
    )
}

export const DateRangeFilterMobileLess = (props, parent) => {

    const filterFields = parent.filterModel.filterFields

    const [state, setState] = useReducer(lockedReducer, {
        dateini: filterFields?.[`${props.field}__gte`]?.filterField || '',
        dateend: filterFields?.[`${props.field}__lte`]?.filterField || ''
    })

    useEffect(() => {
        if (filterFields && (`${props.field}__gte` in filterFields && `${props.field}__lte` in filterFields)) {
            setState({ dateini: filterFields[`${props.field}__gte`].filterField || '', dateend: filterFields[`${props.field}__lte`].filterField || '' })
            return
        }
    }, [filterFields, props.field])


    return (
        <Internal filterFields={filterFields} state={state} setState={setState} props={props}/>
    )
}

const DateRangeFilterLess = (props) => {

    const apiRef = useGridApiContext();

    const filter = apiRef.current.state.filter
    const filterFields = filter.filterFields

    const [state, setState] = useReducer(lockedReducer, {
        dateini: filterFields?.[`${props.field}__gte`]?.filterField || '',
        dateend: filterFields?.[`${props.field}__lte`]?.filterField || ''
    })

    useEffect(() => {
        if (filterFields && (`${props.field}__gte` in filterFields && `${props.field}__lte` in filterFields)) {
            setState({ dateini: filterFields[`${props.field}__gte`].filterField || '', dateend: filterFields[`${props.field}__lte`].filterField || '' })
            return
        }
    }, [filterFields, props.field])

    return (
        <Internal filterFields={filterFields} state={state} setState={setState} props={props}/>
    )
}

export default DateRangeFilterLess