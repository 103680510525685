import React, { useState, useCallback, useContext, useReducer } from 'react'
import { defaultReducer } from '../../Utils/reducers'
import { Tooltip, Box, TextField } from '@mui/material'
import ColorButton from '../../Buttons/ColorButton';
import { toast } from 'react-hot-toast';
import { api } from '../../../services/api';
import { externalComponent } from '../../AppRoutes';
import DefaultModal from '../../Utils/DefaultModal';


const EmailTestDialog = ({ object, handleClose }) => {

    const { setLoader } = useContext(externalComponent)

    const [error, setError] = useState('')

    const [state, setState] = useReducer(defaultReducer, {
        ...object,
        recipients: '',
        subject: '',
        message: ''
    })


    const submitData = async () => {
        setLoader(true)
        const res = await api.post(`/email/testemail/`, state)
        setLoader(false)
        if (res.status === 200) {
            toast.success('Email enviado com sucesso!')
            handleClose()
            return
        }
        setError(res.data)
        toast.error('Ocorreu um erro enviar o email.')

    }

    const detectValidData = useCallback(() => {
        const resp = []
        if (state.recipients.length === 0)
            resp.push(<Box>Destinatários não pode estar vazio.</Box>)
        if (state.subject.length === 0)
            resp.push(<Box>Assunto não pode estar vazio.</Box>)
        if (state.message.length === 0)
            resp.push(<Box>Mensagem não pode estar vazia.</Box>)
        if (resp.length === 0)
            return ''
        return resp
    }, [state.recipients, state.message, state.subject])

    const validData = detectValidData()

    return (
        <>
            <DefaultModal
                title='Enviar mensagem de teste'
                content={
                    <Box className='email-test-rows'>
                        <Tooltip placement="top" title={'Destinatários separados por virgula.'}>
                            <TextField
                                id="desiny"
                                type="text"
                                sx={{ backgroundColor: 'white' }}
                                label="Destinatários"
                                value={state.recipients}
                                size='small'
                                onChange={(e) => { setState({ 'recipients': e.target.value }) }}
                                fullWidth={true}
                            />
                        </Tooltip>

                        <TextField
                            id="subject"
                            type="text"
                            sx={{ backgroundColor: 'white' }}
                            label="Assunto"
                            value={state.subject}
                            size='small'
                            onChange={(e) => { setState({ 'subject': e.target.value }) }}
                            fullWidth={true}
                        />

                        <TextField
                            multiline
                            minRows={4}
                            maxRows={10}
                            id="message"
                            type="text"
                            className='breaking-text-area'
                            sx={{ backgroundColor: 'white' }}
                            label="Mensagem"
                            size='small'
                            value={state.message}
                            onChange={(e) => { setState({ 'message': e.target.value }) }}
                            fullWidth={true}
                        />

                        {error.length > 0 &&
                            <Box className='email-test-error' sx={{ color: 'red' }}>
                                Erro: {error}
                            </Box>}

                    </Box>
                }
                handleClose={handleClose}
                action={
                    <Tooltip placement="left" title={validData}>
                        <Box>
                            <ColorButton
                                onClick={submitData}
                                disabled={validData}
                            >Enviar
                            </ColorButton>
                        </Box>
                    </Tooltip>
                }
            />

        </>
    )
}

export default EmailTestDialog