import React, { useContext, useEffect, useMemo, useReducer } from "react";

import { Box, Tooltip } from "@mui/material";
import { api } from "../../../services/api";
import toast from "react-hot-toast";
//import { getSession } from "../../../contexts/auth";
import { lockedReducer } from "../../Utils/reducers";
import { externalComponent } from "../../AppRoutes";
import ColorButton from "../../Buttons/ColorButton";
import { MobileDatePicker } from "@mui/x-date-pickers";
import ReplyIcon from "@mui/icons-material/Reply";
import dayjs from "dayjs";
import { number2dayweek } from "../../Utils/dayjsptbrhelper";
import MegaAutoComplete from "../../Utils/megaautocomplete";
import ReportTable from "../report_table";
import { maskedTable } from "../../Utils/MaskedInput";
import PrintToPDF from "../../Utils/Print2Pdf";


const searchURL = '/reportsaux/'
const url = "/reports/cashflow/";


const generateColumns = () => {
  const columns = [
    { field: 'document', headerName: 'Documento', width: '10rem', count: true },
    {
      field: 'date', headerName: 'Lançamento', width: '15rem',
      valueGetter: ({ value }) => dayjs(value).format('DD/MM/YYYY')
    },
    {
      field: 'historic', headerName: 'Histórico', width: '35rem'
    },
    {
      field: 'value_in', headerName: 'Entradas', width: '7rem', sum: true,
      valueGetter: ({ value }) => maskedTable.value(value)
    },
    {
      field: 'value_out', headerName: 'Saídas', width: '10rem', sum: true,
      valueGetter: ({ value }) => maskedTable.value(value)
    },
    {
      field: 'value_balance', headerName: 'Balanço', width: '10rem',
      valueGetter: ({ value }) => maskedTable.value(value)
    },
  ]
  return columns
}

const generate = async (state, setState, setLoader) => {
  setLoader(true)
  const payload = {
    date__gte: state.date__gte.format('YYYY-MM-DD'),
    date__lte: state.date__lte.format('YYYY-MM-DD'),
    cashaccount_id: state.cashaccount?.id,
    costcenter_id: state.costcenter?.id
  }

  toast.success('Seu relatório está sendo gerado, aguarde alguns segundos para o seu processamento')
  const res = await api.post(url, payload)
  if (res.status !== 200) {
    toast.error(`Falha ao gerar o relatório. Motivo: ${res.error}`)
  }
  else {
    const columns = generateColumns()
    const aggregated_data = res.data.rows
    setState({ rows: aggregated_data, columns: columns, statistics: res.data.statistics })
  }
  setLoader(false)
}

const calcPerCashAccount = (rows) => {
  const cashaccounts = {}

  rows.forEach(row => {
    if (!(String(row.cashaccount) in cashaccounts))
      cashaccounts[String(row.cashaccount)] = { name: row.cashaccount_name, value: 0, first_value: row.value_balance - row.value_in + row.value_out }
    cashaccounts[String(row.cashaccount)].value = cashaccounts[String(row.cashaccount)].value + row.value_in - row.value_out
  })

  return cashaccounts
}

const CashflowReport = () => {
  const { setLoader } = useContext(externalComponent);

  const [state, setState] = useReducer(lockedReducer, {
    rows: [],
    columns: [],
    statistics: {},
    date__gte: dayjs(),
    date__lte: dayjs(),
    cashaccount: '',
    costcenter: '',
    cost_type: ''
  });

  useEffect(() => {
    setState({
      date__gte: dayjs().subtract(1, 'month'),
      date__lte: dayjs()
    })
  }, [])

  const sum_in = state.rows.map(e => e.value_in).reduce((a, b) => a + b, 0)
  const sum_out = state.rows.map(e => e.value_out).reduce((a, b) => a + b, 0)
  const first_row = state.rows[0]


  const balance_component = useMemo(() => {

    const movements = calcPerCashAccount(state.rows)
    const mapped_statistics = Object.values(state.statistics)
    const filtered_statistics = state.cashaccount ? mapped_statistics.filter(each => each.id === state.cashaccount?.id) : mapped_statistics
    const latest_sum = filtered_statistics.map(each => each.latest_value_balance).reduce((a, b) => a + b, 0)

    return (
      <Box >
        <Box className='apuration-grid'>
          <Box className='headers'>
            <span>Totais do Período</span>
            <span>Saldo Apurado (Entradas - Saídas)</span>
            <span>Saldo Anterior</span>
            <span>Apuração final</span>
          </Box>
          <Box>
            <span>{maskedTable.value(sum_in)}</span>
            <span>{maskedTable.value(sum_in - sum_out)}</span>
            <span>{maskedTable.value(latest_sum)}</span>
            <span>{maskedTable.value(latest_sum + sum_in - sum_out)}</span>
          </Box>
          <Box>
            <span>{maskedTable.value(sum_out)}</span>
          </Box>
        </Box>
        {!state.cashaccount &&
          <Box className='accounts-apuration-grid'>
            <Box className='headers'>
              <span></span>
              <span>Saldo Ant.</span>
              <span>Movimento</span>
              <span>Saldo Atual</span>
            </Box>
            {mapped_statistics.map(statistic => (
              <Box>
                <span>{statistic.name}</span>
                <span>{maskedTable.value(statistic.latest_value_balance || 0)}</span>
                <span>{maskedTable.value(movements[String(statistic.id)]?.value || 0)}</span>
                <span>{maskedTable.value((movements[String(statistic.id)]?.value || 0) + statistic.latest_value_balance || 0)}</span>
              </Box>
            ))}
          </Box>}
      </Box>
    )
  }, [state.rows, first_row, sum_in, sum_out, state.statistics])

  return (
    <>
      <Box className="information-table">
        <Box className='header'>
          <Box className='left' sx={{ gap: '1rem', display: 'flex', flexDirection: 'column' }}>
            <Box className='item-profile-row' sx={{ gap: '1rem', display: 'flex', flexWrap: 'wrap' }}>
              <Tooltip title='Voltar'>
                <ColorButton
                  className='profile-save-button minimized'
                  onClick={() => window.backtobase()}
                >
                  <ReplyIcon />
                </ColorButton>
              </Tooltip>
              <MobileDatePicker
                size='small'
                label='Data de inicio'
                value={dayjs(state.date__gte)}
                format='DD/MM/YYYY'
                sx={{ background: 'white' }}
                onChange={(e) => { setState({ 'date__gte': e }) }}
                slotProps={{ textField: { size: 'small' } }}
                dayOfWeekFormatter={(n, date) => number2dayweek[date.$W]}
                ampm={false}
              />
              <MobileDatePicker
                size='small'
                label='Data de fim'
                value={dayjs(state.date__lte)}
                format='DD/MM/YYYY'
                sx={{ background: 'white' }}
                onChange={(e) => { setState({ 'date__lte': e }) }}
                slotProps={{ textField: { size: 'small' } }}
                dayOfWeekFormatter={(n, date) => number2dayweek[date.$W]}
                ampm={false}
              />
            </Box>
            <Box className='item-profile-row' sx={{ gap: '1rem', display: 'flex', flexWrap: 'wrap' }}>
              <MegaAutoComplete
                sx={{ flex: 1, maxWidth: '15rem' }}
                value={state.cashaccount}
                onChange={(data) => setState({ cashaccount: data })}
                noOptionsText={`Digite para procurar Conta caixa`}
                timeoutUrl={searchURL + 'cashaccount/'}
                timeoutParam={'name'}
                label={'Conta caixa'}
              />
              <MegaAutoComplete
                sx={{ flex: 1, maxWidth: '15rem' }}
                value={state.costcenter}
                onChange={(data) => setState({ costcenter: data })}
                noOptionsText={`Digite para procurar Centros de custo`}
                timeoutUrl={searchURL + 'costcenter/'}
                timeoutParam={'name'}
                label={'Centro de custo'}
              />
              <ColorButton
                sx={{ height: '2.5rem' }}
                onClick={() => generate(state, setState, setLoader)}
              >Gerar relatório
              </ColorButton>
              {state.rows.length > 0 &&
                <ColorButton onClick={() => { PrintToPDF('report-main-screen', null, 'Relatório') }}
                  sx={{ height: '2.5rem' }}>
                  Imprimir relatório
                </ColorButton>}
            </Box>
          </Box>
        </Box>

        <Box id='report-main-screen' className="report-main-screen">
          <ReportTable
            title={
              <>
                <Box>
                  Boletim de caixa entre {state.date__gte?.format('DD/MM/YYYY')} e {state.date__lte?.format('DD/MM/YYYY')}
                </Box>
                <Box>
                  Centro de custos: {state.costcenter ? state.costcenter.label : 'Todos'}
                </Box>
                <Box>
                  Conta caixa: {state.cashaccount ? state.cashaccount.label : 'Todas'}
                </Box>
              </>
            }
            rows={state.rows}
            columns={state.columns}
            aggregate={state.aggregate_by}
            extrainfo={
              balance_component
            }
          />
        </Box>
      </Box>
    </>
  );
};

export default CashflowReport;
