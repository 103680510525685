import React, { useMemo, useLayoutEffect, useReducer, useContext } from 'react'
import DefaultModal from '../Utils/DefaultModal'
import { lockedReducer } from '../Utils/reducers'
import ColorButton from '../Buttons/ColorButton'
import { Box, TextField } from '@mui/material'
import IntegerOnly from '../Utils/IntegerOnly'
import { DataGrid } from '@mui/x-data-grid'
import dayjs from 'dayjs'
import { maskedTable } from '../Utils/MaskedInput'
import { MobileDatePicker } from '@mui/x-date-pickers'
import { number2dayweek } from '../Utils/dayjsptbrhelper'
import { NumericFormatInput } from '../Utils/MaskedInput'
import { externalComponent } from '../AppRoutes'
import toast from 'react-hot-toast'
import { api } from '../../services/api'

const submit = async (data, state, updateUrl, reloadData, setLoader, handleClose) => {
    setLoader(true)
    const res = await api.post(`${updateUrl}mass/`, {...state, list: data})
    if (res.status === 200){
        toast.success(`Lançamento misto adicionado com sucesso`)
        reloadData()
        handleClose()
        setLoader(false)
        return
    }
    toast.error(`Lançamento misto não pôde ser adicionada Motivo: ${res.data}`)
    setLoader(false)
}
const updateRows = (parent, fixed_day, day, parcel_mode, reference_day) => {
    const rows = Number(parent.portion)
    return Array(rows).fill(1).map((_, index) => (
        {
            id: index + 1,
            duedate: (fixed_day ? dayjs(parent.duedate).set('date', reference_day).add(index, 'month') : dayjs(parent.duedate).add(index * day, 'days')).format('YYYY-MM-DD'),
            value: parcel_mode ? Number(parent.value) / Number(rows) : Number(parent.value)
        }
    ))
}

const columns = (rows, setState) => {
    return [
        {
            field: 'duedate', headerName: 'Vencimento', flex: 1, editable: true,
            renderEditCell: (params) => (
                <MobileDatePicker
                    size='small'
                    value={dayjs(params?.value)}
                    format='DD/MM/YYYY'
                    sx={{ flex: 1 }}
                    onChange={(e) => {
                        const row = rows.find(each => each.id === params.id)
                        if (row) {
                            row.duedate = e.format('YYYY-MM-DD')
                        }
                    }}
                    slotProps={{ textField: { size: 'small' } }}
                    dayOfWeekFormatter={(n, date) => number2dayweek[date.$W]}
                    ampm={false}
                />

            ),
            renderCell: ({ value }) => dayjs(value).format('DD/MM/YYYY'),
        }, {
            field: 'value', headerName: 'Valor', flex: 1, editable: true,
            renderEditCell: (params) => (
                <TextField
                    id={`valor-decider-${params.id}`}
                    type="text"
                    value={params.value}
                    onChange={(e) => {
                        const row = rows.find(each => each.id === params.id)
                        if (row) {
                            row.value = Number(e.target.value)
                        }
                    }}
                    fullWidth={false}
                    size='small'
                    InputProps={{
                        inputComponent: NumericFormatInput
                    }}
                />
            ),
            renderCell: ({ value }) => {
                return maskedTable['value'](value)
            }
        },
    ]
}

const Divided = ({ parent, setParent, updateUrl, reloadData, handleClose }) => {

    const {setLoader} = useContext(externalComponent)

    const [state, setState] = useReducer(lockedReducer, {
        fixed_day: true,
        day: '1',
        reference_day: dayjs(parent.duedate).$D,
        parcel_mode: true,
        rows: []
    })

    useLayoutEffect(() => {
        const newrows = updateRows(parent, state.fixed_day, state.day, state.parcel_mode, state.reference_day)
        if (state.day) {
            setState({ rows: newrows, day: state.fixed_day ? String(IntegerOnly(state.day, 0, 28)) : String(IntegerOnly(state.day, 0, 99)) })
        }
    }, [parent, state.fixed_day, state.day, state.parcel_mode, state.reference_day])

    return (
        <DefaultModal
            title='Lançamento misto'
            handleClose={() => setParent({ divided_modal: false })}
            content={
                <Box className='item-content-modal noborder' sx={{ maxHeight: 'calc(78vh - 128px)' }}>
                    <Box sx={{ display: 'flex', gap: '1rem' }}>
                        <Box className='user-activity-buttons' sx={{ whiteSpace: 'pre' }}>
                            <ColorButton reverse={!state.fixed_day} onClick={() => setState({ fixed_day: true })}>Dia fixo</ColorButton>
                            <ColorButton reverse={state.fixed_day} onClick={() => setState({ fixed_day: false })}>Adicionais</ColorButton>
                        </Box>
                        <TextField
                            id="additionaldays"
                            type="text"
                            label={state.fixed_day ? "Dia de referência" : "Dias adicionais"}
                            value={state.fixed_day ? state.reference_day : state.day}
                            onChange={(e) => {
                                state.fixed_day ?
                                    setState({ 'reference_day': String(IntegerOnly(e.target.value, 0, 28)) })
                                    :
                                    setState({ 'day': String(IntegerOnly(e.target.value, 0, 99)) })
                            }}
                            fullWidth={false}
                            size='small'
                            inputProps={{
                                maxLength: 2
                            }}
                        />
                    </Box>
                    <Box className='user-activity-buttons' sx={{ whiteSpace: 'pre' }}>
                        <ColorButton reverse={!state.parcel_mode} onClick={() => setState({ parcel_mode: true })}>Dividir o valor entre as parcelas</ColorButton>
                        <ColorButton reverse={state.parcel_mode} onClick={() => setState({ parcel_mode: false })}>Distribuir valor para cada parcela</ColorButton>
                    </Box>
                    <DataGrid
                        className='mixed-launch'
                        rows={state.rows}
                        columns={useMemo(() => columns(state.rows, setState), [state.rows])}
                        hideFooter={true}
                        density='compact'
                        onCellEditStop={(params) => {
                            const row = state.rows.find(each => each.id === params.id)
                            if (row) {
                                Object.assign(row, params.row)
                                setState({rows: [...state.rows]})
                            }
                        }}
                    />
                </Box>
            }
            action={
                <Box>
                    <ColorButton
                        onClick={() => submit(state, parent, updateUrl, reloadData, setLoader, handleClose)}
                    >Adicionar
                    </ColorButton>
                </Box>
            }
        />

    )
}

export default Divided