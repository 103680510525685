import { useContext, useLayoutEffect, useState } from 'react';
import Container from '@mui/material/Container';
import InputsLogin from './inputsLogin';
import Footer from './footer';
import Header from './header';
import './login.css'
import { Box } from '@mui/material';
import { AuthContext } from '../../contexts/auth';
import Loader from '../Loader/loader';

export const AutoLoginComponent = () => {
    const { login } = useContext(AuthContext);
    const [ready, setReady] = useState(false)
   

    useLayoutEffect(()=>{
        if (!ready){
            sessionStorage.clear()
            login(null, null, window.location.search.split('token=').pop().replace('/',''))
            setReady(true)
        }
            
    }, [login, ready])
    return (
        <><Loader external show={true} /></>
    )
}

const LoginComponent = (props) => {

    const changeStatusValue = (status) => {
        props.onLogin(status)
    }

    return (
        <Box sx={{padding:'2rem'}} className='root-container'>
            <Container component="main" maxWidth="xs" className="mainContainer">
                <Header />
                <InputsLogin onGetLogin={changeStatusValue} />
                <Footer />
            </Container>
        </Box>
    );
};

export default LoginComponent;