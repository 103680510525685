import React, { useEffect, useMemo, useState } from 'react'
import { useGridApiContext, useGridRootProps } from '@mui/x-data-grid';
import { Box } from '@mui/material';
import ColorButton from '../Buttons/ColorButton';
import StringFilter, {StringFilterMobile} from './filterDeps/stringfilter';
import NumberFilter, {NumberFilterMobile} from './filterDeps/numberFilter';
import DateFilter, {DateFilterMobile} from './filterDeps/dateFilter';
import DateRangeFilter, {DateRangeFilterMobile} from './filterDeps/daterangeFilter';
import DateRangeFilterLess, {DateRangeFilterMobileLess} from './filterDeps/daterangeFilterLess';
import ChoicesFilter, {ChoicesFilterMobile} from './filterDeps/choicesFilter';
import StringListFilter, {StringListFilterMobile} from './filterDeps/stringlistfilter';

const possibleFilterTypes = {
    'string': StringFilter,
    'stringlist': StringListFilter,
    'number': NumberFilter,
    'date': DateFilter,
    'daterange': DateRangeFilter,
    'daterangeless': DateRangeFilterLess,
    'choices': ChoicesFilter
}

const possibleFilterTypesMobile = {
    'string': StringFilterMobile,
    'stringlist': StringListFilterMobile,
    'number': NumberFilterMobile,
    'date': DateFilterMobile,
    'daterange': DateRangeFilterMobile,
    'daterangeless': DateRangeFilterMobileLess,
    'choices': ChoicesFilterMobile
}

const generateFilterLookup = (key, value) => {
    return value.selectField ? `${key}__${value.selectField}` : key
}

const clearFilters = (filter, possibleFilters) => {
    possibleFilters.forEach(each => {
        switch (each.filterType) {
            case 'daterangeless':
            case 'daterange':
                filter.filterFields[`${each.field}__gte`] = {}
                filter.filterFields[`${each.field}__lte`] = {}
                break
            default:
                filter.filterFields[each.field] = {}
        }

    })
}

// You should declare this filterType on columns
const Filters = () => {

    const apiRef = useGridApiContext()
    const rootProps = useGridRootProps()

    const filter = apiRef.current.state.filter
    const filterState = rootProps.slots.filterState || {}
    const setState = apiRef.current.setState

    const [, setReload] = useState(false)
    const reloadState = () => setReload(p => !p)

    const possibleFilters = useMemo(() => Object.values(apiRef.current.state.columns.lookup).filter(each => each.filterable), [apiRef])

    useEffect(() => {
        if (!('filterFields' in filter)) {
            filter.filterFields = {}
            clearFilters(filter, possibleFilters)
            reloadState()
        }
    }, [filter, possibleFilters])

    return (
        <Box className='table-column-popover'>
            <Box className='table-column-list' sx={{ gap: '1rem' }}>
                {possibleFilters.map(each =>
                    each.filterType in possibleFilterTypes ? possibleFilterTypes[each.filterType](each) : null)}
            </Box>
            <Box className='table-filter-choose'>
                <ColorButton reverse={filter.filterModel.or} onClick={() => {
                    filter.filterModel.or = false
                    reloadState()
                }
                } >E</ColorButton>
                <ColorButton reverse={!filter.filterModel.or} onClick={() => {
                    filter.filterModel.or = true
                    reloadState()
                }} >OU</ColorButton>
            </Box>
            <Box className='table-column-popover-footer'>
                <ColorButton
                    reverse={true}
                    onClick={() => {
                        filter.filterFields = {}
                        clearFilters(filter, possibleFilters)
                        setState({ ...apiRef.current.state, filter: { ...filter } })
                        rootProps.updateTableState({ filterModel: { items: [], customItems: {} } })
                        reloadState()
                    }}>Limpar filtros</ColorButton>
                <ColorButton
                    reverse={true}
                    onClick={() => {
                        const newFilter = Object.fromEntries(
                            Object.entries(filter.filterFields).filter(([, value]) =>
                                value.filterField !== undefined).map(([key, value]) => ([generateFilterLookup(key, value), value.filterField]))) || {}
                        apiRef.current.setFilterModel({ ...filter.filterModel, customItems: { ...filterState, ...newFilter } })

                        reloadState()
                    }}>Buscar</ColorButton>
            </Box>
        </Box>
    )
}


export const MobileFilters = ({parent, setParent, onFilterModelChange, columns}) => {

    const [, setReload] = useState(false)
    const reloadState = () => setReload(p => !p)

    const possibleFilters = columns.filter(each => each.filterable !== false)

    const filter = parent.filterModel

    useEffect(() => {
        if (!('filterFields' in filter)) {
            filter.filterFields = {}
            clearFilters(filter, possibleFilters)
            reloadState()
        }
    }, [filter, possibleFilters])

    return (
        <Box className='table-column-popover'>
            <Box className='table-column-list' sx={{ gap: '1rem' }}>
                {possibleFilters.map(each =>
                    each.filterType in possibleFilterTypesMobile ? possibleFilterTypesMobile[each.filterType](each, parent) : null)}
            </Box>
            <Box className='table-filter-choose'>
                <ColorButton reverse={filter.or} onClick={() => {
                    filter.or = false
                    reloadState()
                }
                } >E</ColorButton>
                <ColorButton reverse={!filter.or} onClick={() => {
                    filter.or = true
                    reloadState()
                }} >OU</ColorButton>
            </Box>
            <Box className='table-column-popover-footer'>
                <ColorButton
                    reverse={true}
                    onClick={() => {
                        filter.filterFields = {}
                        clearFilters(filter, possibleFilters)
                        setParent({ filterModel: { items: [], customItems: {} }})
                        reloadState()
                    }}>Limpar filtros</ColorButton>
                <ColorButton
                    reverse={true}
                    onClick={() => {
                        const newFilter = Object.fromEntries(
                            Object.entries(filter.filterFields).filter(([, value]) =>
                                value.filterField !== undefined).map(([key, value]) => ([generateFilterLookup(key, value), value.filterField]))) || {}
                        onFilterModelChange({ ...filter, customItems: { ...filter.customItems, ...newFilter } })

                        reloadState()
                    }}>Buscar</ColorButton>
            </Box>
        </Box>
    )
}

export default Filters